import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonNote,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import './AdminPersonalOverview.css'
import createAxiosInstance from "../services/AxiosInstance";
import {User} from "../model/User";
import {today} from "ionicons/icons";
import {useToast} from "../Utils/useToasts";
import TransactionCard from "../components/TransactionCard";
import ProfilePicture from "../components/ProfilePicture";

const AdminPersonalOverview: React.FC = () => {

    const [user, setUser] = useState<User>();
    const [transactions, setTransactions] = useState<any>([]);
    const [currentBalance, setCurrentBalance] = useState<number>(0);
    const [remark, setRemarks] = useState<string>('Opwaarderen')
    const {playerId} = useParams<{ playerId?: string }>();
    const [newTransactionAmount, setNewTransactionAmount] = useState<number>(0)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string | string[]>();
    const showToasts = useToast();
    const axios = createAxiosInstance();

    const fetchPlayerData = () => {
        axios.get(`/player/${playerId}`)
            .then(res => {
                console.log(res.data)
                setUser(res.data.player)
                setCurrentBalance(res.data.balance)
                setTransactions(res.data.transactions.reverse())
            })
    }

    const keyPress = (event: { key: string; }) => {
        if (event.key === 'Enter' && newTransactionAmount !== 0) {
            const newTransaction = {
                deltaSaldo: newTransactionAmount,
                remark: remark
            }

            axios.post(`/transaction/${playerId}`, newTransaction)
                .then(res => {
                    fetchPlayerData()
                    console.log(res.data)
                    showToasts(`€ ${res.data.deltaSaldo} is opgewaardeerd!`, 'success')
                })
                .finally(() => {
                    setNewTransactionAmount(0);
                    setRemarks("Opwaarderen");
                })
        }
    }

    const handleSave = () => {
        if (selectedDate === null || selectedDate === undefined || selectedDate === '') {
            showToasts('Selecteer eerst een datum!', 'danger')
        } else {
            const date = selectedDate instanceof Array ? new Date(selectedDate[0]) : new Date(selectedDate);
            const dateBody = {
                date: date
            }
            axios.post(`/evening/check-in/admin/${playerId}`, dateBody)
                .then(res => {
                    fetchPlayerData()
                    showToasts(`${res.data.name} is op ${res.data.checkInDate} ingecheckt!`, 'success')
                })
                .catch(error => {
                    if (error.request.status === 405) {
                        showToasts('Al ingecheckt', 'danger')
                    } else {
                        showToasts(`Er is iets mis gegaan, laat Ties de tering zooi maar weer uitzoeken`, 'danger')
                    }
                })
        }
        setShowModal(false);
    };

    useEffect(() => {
        fetchPlayerData()
    }, [])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton/>
                    </IonButtons>
                    <IonTitle>Saldo aanpassen</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonCard className='user-card'>
                    <IonCardHeader>
                        <div className={'user-container'}>
                            {user && <ProfilePicture profilePicUrl={(user.profilePictureUrl?.length !== 0 && user.profilePictureUrl) ? `${process.env.REACT_APP_STORAGE}${user.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                                     height={50}
                                                     width={50}
                                                     showEditable={false}/>
                            }
                            <div className={'user-info-container'}>
                                <IonCardTitle class='user-card-header'>{user?.name}</IonCardTitle>
                                <IonNote class='user-card-header'>Saldo: € {currentBalance}</IonNote>
                                <IonNote class='user-card-header'>Email: {user?.emailAddress != null ? user?.emailAddress : "Geen email ingevuld"}</IonNote>
                            </div>
                        </div>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Saldo mutatie:</IonLabel>
                            <IonInput value={newTransactionAmount} onKeyPress={keyPress}
                                      onIonInput={(e: any) => setNewTransactionAmount(e.target.value!)}
                                      placeholder="Hoeveelheid geld"
                                      clearInput type="number"/>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Opmerking:</IonLabel>
                            <IonInput value={remark} placeholder="Opmerking"
                                      onKeyPress={keyPress}
                                      onIonInput={(e: any) => setRemarks(e.target.value!)}
                                      clearInput/>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
                <IonList>
                    {transactions.map((value: any, key: any) => (
                        <TransactionCard key={key} amount={value.playerDelta} description={value.remark}
                                         date={value.transDate} isTransaction={value.isTransaction}/>
                    ))}
                </IonList>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => setShowModal(true)}>
                        <IonIcon icon={today}/>
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonLabel>Date Picker</IonLabel>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        <IonDatetime display-format="YYYY/MM/DD"
                                     value={selectedDate}
                                     onIonChange={e => setSelectedDate(e.detail.value!)}
                                     size="cover" color="primary" presentation="date"
                                     style={{"--background": "#222222"}}>
                        </IonDatetime>
                        <IonButton onClick={handleSave}>Check in</IonButton>
                    </div>
                </IonContent>
            </IonModal>
        </IonPage>

    );
}

export default AdminPersonalOverview;
