// src/pages/ResetPasswordPage.tsx
import React, {useState} from 'react';
import {IonButton, IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar,} from '@ionic/react';
import {useHistory, useParams} from 'react-router-dom';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";

const ResetPasswordPage: React.FC = () => {
    const {token} = useParams<{ token: string }>();
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const axios = createAxiosInstance();
    const showToasts = useToast();
    const history = useHistory();

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            showToasts('Wachtwoorden komen niet overeen.', 'danger');
        } else if(newPassword.length < 8) {
            showToasts('Wachtwoord te kort', 'danger')
        } else {
            await axios.post('/auth/reset-password', {
                token: token,
                newPassword: newPassword
            }).then(res => {
                showToasts('Wachtwoord succesvol gereset.', 'success');
                history.push('/login');
            }).catch(err => {
                showToasts('Er is iets mis gegaan.', 'danger');
            });
        }
    };


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Herstel wachtwoord</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div style={{maxWidth: '500px', margin: 'auto'}}>
                    <h2>Herstel je wachtwoord</h2>
                    <p>
                        Vul een nieuw wachtwoord in. Deze kan je in het vervolg gebruiken om in te loggen.
                    </p>
                    <IonInput
                        type="password"
                        placeholder="Nieuw wachtwoord"
                        value={newPassword}
                        onIonInput={(e) => setNewPassword(e.detail.value!)}
                    />
                    <IonInput
                        type="password"
                        placeholder="Bevestig wacthwoord"
                        value={confirmPassword}
                        onIonInput={(e) => setConfirmPassword(e.detail.value!)}
                    />
                    <IonButton onClick={handleResetPassword}>
                        Herstel wachtwoord
                    </IonButton>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default ResetPasswordPage;
