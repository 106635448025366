import React, {useEffect} from "react";
import createAxiosInstance from "../../services/AxiosInstance";
import {useToast} from "../../Utils/useToasts";
import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import MqttSceneBuilderComponent from "./components/MqttSceneBuilderComponent";

const MqttSceneOverview: React.FC = () => {
    useEffect(() => {
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Scene's</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <MqttSceneBuilderComponent />
            </IonContent>
        </IonPage>
    );
}

export default MqttSceneOverview;
