import {IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonList, IonRow} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import createAxiosInstance from "../services/AxiosInstance";
import io from "socket.io-client";
import "./Dashboard.css"
import LineChartComponent from "./LineChartComponent";
import LineChartTotals from "./LineChartTotals";
import QRCodeComponent from "./QRCodeComponent";

const Dashboard: React.FC = () => {
    const [guests, setGuests] = useState<any[]>([]);
    const [eventMessages, setEventMessages] = useState<any[]>([]);
    const axios = createAxiosInstance();
    const [lobbyData, setLobbyData] = useState<any>(null);
    const [totals, setTotals] = useState<any>(null);
    const [amountOfDoublers, setAmountOfDoublers] = useState<number>(0);
    const [amountOfStreets, setAmountOfStreets] = useState<number>(0);
    const qrValueRef = useRef<string>('');
    const lobbyIdRef = useRef<string>('');

    function fetchPresentGuests() {
        axios.get('/evening/present-guests')
            .then(res => {
                if (res.data[0] !== undefined) {
                    setGuests(res.data)
                }
            })
    }

    const fetchLobbyData = async () => {
        const idToUse =  lobbyIdRef.current !== '' ? lobbyIdRef.current : null;
        if (idToUse) {
            try {
                await axios.get(`/dertigen/${idToUse}/graph`)
                    .then(res => {
                        setLobbyData(res.data.lobby)
                        setAmountOfDoublers(res.data.amountOfDoublers)
                        setAmountOfStreets(res.data.amountOfStreetThrows)
                    }).catch(err => {
                        console.error(err)
                    })
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                await axios.get('/dertigen/latest')
                    .then(res => {
                        setLobbyData(res.data.lobby)
                        setAmountOfDoublers(res.data.amountOfDoublers)
                        setAmountOfStreets(res.data.amountOfStreetThrows)
                    }).catch(err => {
                        console.error(err)
                    })
            } catch (error) {
                console.error(error);
            }
        }
    };

    const fetchTotalsData = async () => {
        try {
            const response = await axios.get('/dertigen/total');
            setTotals(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_SOCKET}`);
        fetchPresentGuests()
        fetchLobbyData()
        fetchTotalsData()
        socket.on("connect", () => {
            socket.emit('lobby', 'dashboard')
        })

        socket.on('id', (message) => {
            console.log(message)
            qrValueRef.current = message;

            socket.on(`dashboard-${qrValueRef.current}`, (lobbyId: string) => {
                lobbyIdRef.current = lobbyId;
                fetchLobbyData()
            });
        })

        socket.on('event', (message) => {
            fetchPresentGuests()
            const now: Date = new Date();
            const hours: number = now.getHours();
            const minutes: number = now.getMinutes();

            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            const newEvent = {
                message: message,
                time: `${formattedHours}:${formattedMinutes}`
            };

            setEventMessages(prevMessages => [newEvent, ...prevMessages].slice(0, 10));
        })

        socket.on('refresh', () => {
            fetchLobbyData()
        })

        return () => {
            socket.disconnect()
            socket.off('connect');
            socket.off("disconnect")
            socket.off('event');
        }
    }, [])


    return (
        <IonGrid className={'dashboard-grid'}>
            <div className={'vertical-line'}/>
            <div className={'horizontal-line'}/>

            <IonRow class={'dashboard-row'}>
                <IonCol>
                    <h1>Aanwezigheid</h1>
                    <IonList class=" flex-container">
                        {guests && guests.length > 0 ? guests.map((guest) => (
                            <IonCard className={`present-card${guest.saldo < 0 ? "-debt" : ""} flex-item`}
                                     key={guest._id}>
                                <h2>{guest.name}</h2>
                            </IonCard>
                        )) : null}
                    </IonList>
                </IonCol>
                <IonCol>
                    <h1>Live feed</h1>
                    <IonList class="feed-column">
                        {eventMessages.map((chatMessage, index) => (
                            <IonCard className="event-card" key={index}>
                                <h2>{chatMessage.message}</h2>
                                <h2>{chatMessage.time}</h2>
                            </IonCard>
                        ))}
                    </IonList>
                </IonCol>
            </IonRow>
            <IonRow class={'dashboard-row'}>
                <IonCol class={'center-chart'}>
                    {lobbyData && (
                        <>
                            <div className='chart'>
                                <LineChartComponent lobby={lobbyData}/>
                            </div>
                            <div className={'lobby-statistics-container'}>
                                <IonCard>
                                    <IonCardContent className="custom-grid-statistics">
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Straatjes</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{amountOfStreets}</p>
                                            </IonCardContent>
                                        </div>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Verdubbelaars</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{amountOfDoublers}</p>
                                            </IonCardContent>
                                        </div>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">QR - scan</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                {qrValueRef.current && <QRCodeComponent value={qrValueRef.current}/>}
                                            </IonCardContent>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        </>
                    )}
                </IonCol>
                <IonCol class={'center-chart'}>
                    {totals && (
                        <div className='chart'>
                            <LineChartTotals data={totals}/>
                        </div>
                    )}
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}
export default Dashboard;
