import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from '@ionic/react';
import {BeerDiscount} from "../model/BeerDiscount";
import './BeerDiscounts.css'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

type Props = {
    discounts: BeerDiscount[];
};

const BeerDiscounts: React.FC<Props> = ({discounts}) => {

    return (
        <Swiper  slidesPerView={1} spaceBetween={30} loop={true}>
            {discounts.map((discount, index) => (
                <SwiperSlide key={index}>
                    <IonCard className='card-styling'>
                        <IonCardHeader>
                            <IonCardTitle className='ion-card-title'>{discount.bierMerk}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p className='p-styling'>Normaal: {discount.normalPrijs}</p>
                            <p className='p-styling'>Korting prijs: {discount.discountPrijs}</p>
                            <p className='p-styling'>Winkel: {discount.supermarkt}</p>
                            <p className='p-styling'>Soort: {discount.extraInfo}</p>
                        </IonCardContent>
                    </IonCard>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BeerDiscounts;
