// src/components/Dice.tsx
import React from 'react';
import './Dice.css'

interface DiceProps {
    value: number;
    isFrozen: boolean;
    onClick: () => void;
}

const dice1 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 1" clip-path="url(#clip0_101_2)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="20" cy="20" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_2">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const dice2 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 2" clip-path="url(#clip0_101_36)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="10" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 2" cx="30" cy="9" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_36">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const dice3 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 3" clip-path="url(#clip0_101_9)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="10" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 3" cx="20" cy="20" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 2" cx="30" cy="9" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_9">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const dice4 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 4" clip-path="url(#clip0_101_14)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="10" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 3" cx="30" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 4" cx="10" cy="10" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 2" cx="30" cy="9" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_14">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const dice5 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 5" clip-path="url(#clip0_101_20)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="10" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 5" cx="20" cy="20" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 3" cx="30" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 4" cx="10" cy="10" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 2" cx="30" cy="9" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_20">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const dice6 = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 6" clip-path="url(#clip0_101_27)">
            <rect width="40" height="40" fill="white"/>
            <rect id="Rectangle 1" width="40" height="40" fill="black"/>
            <circle id="Ellipse 1" cx="10" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 5" cx="10" cy="20" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 6" cx="30" cy="20" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 3" cx="30" cy="30" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 4" cx="10" cy="10" r="4" fill="#D9D9D9"/>
            <circle id="Ellipse 2" cx="30" cy="9" r="4" fill="#D9D9D9"/>
        </g>
        <defs>
            <clipPath id="clip0_101_27">
                <rect width="40" height="40" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const diceFaces = [dice1, dice2, dice3, dice4, dice5, dice6];

const Dice: React.FC<DiceProps> = ({value, isFrozen, onClick}) => {
    const svg = diceFaces[value - 1];
    const borderStyle = isFrozen ? '2px solid blue' : 'none';
    return (
        <div
            className={`dice ${isFrozen ? 'frozen' : ''}`}
            onClick={onClick}
        >
            <div style={{ border: borderStyle }}>
                {svg}
            </div>
        </div>
    );
};

export default Dice;
