import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {Paragraph} from "../model/Paragraph";
import {Statute} from "../model/Statute";
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";

type DertigenStatutesAddProps = {
    closeModal: () => void;
    existingStatute?: Statute;
    isEditing?: boolean;
};

const DertigenStatutesAdd: React.FC<DertigenStatutesAddProps> = ({closeModal, existingStatute, isEditing}) => {
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);
    const [isTitleValid, setIsTitleValid] = useState<boolean>(false);
    const axios = createAxiosInstance();
    const showToasts = useToast();


    const handleParagraphChange = (index: number, value: string) => {
        const newParagraphs = paragraphs.map((paragraph, pIndex) => {
            if (index === pIndex) {
                return {...paragraph, content: value};
            }
            return paragraph;
        });
        setParagraphs(newParagraphs);
    };

    const addParagraph = () => {
        setParagraphs([...paragraphs, {content: ''}]);
    };

    const removeParagraph = (index: number) => {
        const newParagraphs = paragraphs.filter((_, pIndex) => index !== pIndex);
        setParagraphs(newParagraphs);
    };

    const handleTitleChange = (value: string) => {
        setTitle(value);
        setIsTitleValid(value.trim().length > 0);
    };

    const handleSubmit = () => {
        if (!isTitleValid) {
            return;
        }

        const isAllParagraphsValid = paragraphs.every(paragraph => paragraph.content.trim().length > 0);

        if (!isAllParagraphsValid) {
            return;
        }

        const statuteData = {title, content, paragraphs};
        if (isEditing) {
            axios.put(`/statutes/${existingStatute?._id}`, statuteData)
                .then(res => {
                    closeModal()
                }).catch(err => {
                console.error(err)
                showToasts("Er is iets misgegaan", "danger")
            })
        } else {
            axios.post("/statutes", statuteData)
                .then(res => {
                    closeModal()
                }).catch(err => {
                console.error(err)
                showToasts("Er is iets misgegaan", "danger")
            })
        }
        closeModal();
    };


    useEffect(() => {
        if (isEditing && existingStatute) {
            setTitle(existingStatute.title);
            setContent(existingStatute.content);
            setParagraphs(existingStatute.paragraphs);
            setIsTitleValid(existingStatute.title.trim().length > 0);
        }
    }, [isEditing, existingStatute]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Nieuw statuut</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel position="floating">Titel</IonLabel>
                        <IonInput value={title} onIonInput={e => handleTitleChange(e.detail.value!)}/>
                        {!isTitleValid && <p style={{color: 'red', fontWeight: 'bolder'}}>Titel is verplicht</p>}
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Beschrijving</IonLabel>
                        <IonInput value={content} onIonInput={e => setContent(e.detail.value!)}/>
                    </IonItem>
                    {paragraphs.map((paragraph, index) => (
                        <IonItem key={index} style={{borderColor: paragraph.content.trim() ? '' : 'red'}}>
                            <IonLabel position="floating">Paragraph {index + 1}</IonLabel>
                            <IonInput
                                value={paragraph.content}
                                onIonInput={e => handleParagraphChange(index, e.detail.value!)}
                            />
                            {paragraph.content.trim().length === 0 &&
                                <p style={{color: 'red', fontWeight: 'bolder'}}>Paragraaf kan niet leeg zijn</p>}
                            <IonButton onClick={() => removeParagraph(index)} color="danger">Remove</IonButton>
                        </IonItem>
                    ))}
                    <IonItem lines="none">
                        <IonButton expand="block" onClick={addParagraph}>Nieuwe paragraaf</IonButton>
                    </IonItem>
                    <IonItem lines="none">
                        <IonButton expand="block" onClick={handleSubmit}>Voeg statuut toe</IonButton>
                    </IonItem>
                    <IonItem lines="none">
                        <IonButton expand="block" onClick={closeModal}>Sluiten</IonButton>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default DertigenStatutesAdd;
