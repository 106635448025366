import React, {useEffect, useState} from "react";
import {
    IonButtons,
    IonCard,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import createAxiosInstance from "../services/AxiosInstance";
import {useParams} from "react-router-dom";
import "./presenceList.css"

const PresenceList: React.FC = () => {
    const [evenings, setEvenings] = useState<any>([]);
    const {userId} = useParams<{ userId?: string }>();
    const axios = createAxiosInstance();

    useEffect(() => {
        const fetchEvenings = async () => {
            try {
                const response = await axios.get(`/evening/evenings/${userId}`);
                const eveningsData = response.data.map((evening: any) => ({
                    ...evening,
                    formattedDate: formatDate(evening.eveningDate),
                    dayOfWeek: getDayOfWeek(evening.eveningDate)
                }));
                setEvenings(eveningsData);
            } catch (error) {
                console.error("Error fetching evenings:", error);
            }
        };

        fetchEvenings();
    }, []);

    const formatDate = (dateTimeString: string) => {
        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleDateString("nl-NL", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    };

    const getDayOfWeek = (dateTimeString: string) => {
        const dateTime = new Date(dateTimeString);
        const dayOfWeek = dateTime.toLocaleDateString("nl-NL", {
            weekday: "long"
        });
        const firstLetter = dayOfWeek.charAt(0).toUpperCase();
        const restOfLetters = dayOfWeek.slice(1);
        return firstLetter + restOfLetters;
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Jouw aanwezige avonden</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {[...evenings].reverse().map((evening: any) => (
                        <IonCard key={evening._id}>
                                <h1 className={'presence-date-title'}>{evening.dayOfWeek} {evening.formattedDate}</h1>
                                <IonList class={'flex-container-presence'}>
                                    {evening.players.map((player: any) => (
                                        <IonCard className={'present-card-overview flex-item-presence'} key={player._id}>
                                            <IonCardTitle class={'presence-name-header'}>
                                                {player.name}
                                            </IonCardTitle>
                                        </IonCard>
                                    ))}
                                </IonList>
                        </IonCard>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default PresenceList;
