import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Ranglijsten.css';
import {useEffect, useState} from "react";
import createAxiosInstance from "../services/AxiosInstance";
import ProfilePicture from "../components/ProfilePicture";
import LoadingSpinner from "../components/LoadingSpinner";

const Ranglijsten: React.FC = () => {
    const [attendance, setAttendance] = useState<any>([])
    const [pukes, setPukes] = useState<any>([])
    const [expenses, setExpenses] = useState<any>([])
    const [dertigenRankings, setDertigenRankings] = useState<any>([])
    const [selectedLeaderboard, setSelectedLeaderboard] = useState('attendance');
    const [selectedDertigenLeaderboard, setSelectedDertigenLeaderboard] = useState('stripe-goal');
    const [loading, setLoading] = useState<boolean>(false)

    const axios = createAxiosInstance();

    function fetchEveningRankings() {
        axios.get('/player/eveningRankings')
            .then(res => {
                let sortedEvenings = res.data.sort((a: any, b: any) => b.data - a.data);
                setAttendance(sortedEvenings)
            })
    }

    function fetchPukeRankings() {

        axios.get('/player/pukeRankings')
            .then(res => {
                let sortedPukes = res.data.sort((a: any, b: any) => b.data - a.data);
                setPukes(sortedPukes)
            })
    }

    function fetchExpenseRankings() {

        axios.get('/player/expenseRankings')
            .then(res => {
                let sortedExpense = res.data.sort((a: any, b: any) => b.data - a.data);
                setExpenses(sortedExpense)
            })
    }

    function fetchStripeGoalRankings() {
        setLoading(true)
        axios.get('/player/weeklyTargetRanking')
            .then(res => {
                let sortedGoalAchievedPercentage = res.data.sort((a: any, b: any) => b.data - a.data);
                setDertigenRankings(sortedGoalAchievedPercentage)
            })
            .finally(() => setLoading(false));

    }

    function fetchDoublerRankings() {
        setLoading(true)
        axios.get('/player/doublersRanking')
            .then(res => {
                let doublersThrown = res.data.sort((a: any, b: any) => b.data - a.data);
                setDertigenRankings(doublersThrown)
            })
            .finally(() => setLoading(false));

    }

    function fetchDertigenRecordRankings() {
        setLoading(true)
        axios.get('/dertigen/recordRanking')
            .then(res => {
                let sortedDertigenRecords = res.data.sort((a: any, b: any) => b.data - a.data);
                setDertigenRankings(sortedDertigenRecords)
            })
            .finally(() => setLoading(false));
    }

    const changeDertigenLeaderboard = (value: any) => {
        console.log(value)
        setSelectedDertigenLeaderboard(value)
        if (value === 'dertigen-record') {
            clickDertigenRecordList()
        } else if (value === 'doubler') {
            clickDoublerList()
        } else if (value === 'stripe-goal') {
            clickStripeTargetList()
        }
    }

    useEffect(() => {
        fetchExpenseRankings()
        fetchEveningRankings()
        fetchPukeRankings()
    }, [])

    const clickStripeTargetList = () => {
        setSelectedDertigenLeaderboard('stripe-goal')
        setSelectedLeaderboard('dertigen')
        fetchStripeGoalRankings()
    }

    const clickDoublerList = () => {
        setSelectedDertigenLeaderboard('doubler')
        setSelectedLeaderboard('dertigen')
        fetchDoublerRankings()
    }

    const clickDertigenRecordList = () => {
        setSelectedDertigenLeaderboard('dertigen-record')
        setSelectedLeaderboard('dertigen')
        fetchDertigenRecordRankings()
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Ranglijsten</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonImg src={'https://cdn-icons-png.flaticon.com/512/983/983763.png'}
                        style={{
                            flex: .30,
                            height: window.innerHeight / 4,
                            width: window.innerWidth,
                            borderRadius: 60 / 2
                        }}/>

                <IonGrid>
                    <IonRow class="ion-align-items-center">
                        <IonCol class="ion-text-center">
                            <IonButton onClick={() => setSelectedLeaderboard('attendance')}>Aanwezigheid</IonButton>
                        </IonCol>
                        <IonCol class="ion-text-center">
                            <IonButton onClick={() => setSelectedLeaderboard('expenses')}>Uitgegeven</IonButton>
                        </IonCol>
                        <IonCol class="ion-text-center">
                            <IonButton onClick={() => setSelectedLeaderboard('pukes')}>Barry's</IonButton>
                        </IonCol>
                        <IonCol class="ion-text-center">
                            <IonButton onClick={() => clickStripeTargetList()}>Dertigen</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {selectedLeaderboard === 'attendance' && (
                    <IonList>
                        {attendance.map(((value: any, index: number) => (
                            <IonItem key={index}>
                                <IonListHeader>
                                    <IonLabel class="ion-text-center">
                            <span className="large-medal">
                            {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : ""}
                        </span>
                                        {value.name + " is " + value.data + " dagen aanwezig geweest"}
                                    </IonLabel>
                                    <ProfilePicture
                                        profilePicUrl={value.profilePictureUrl !== undefined ? `${process.env.REACT_APP_STORAGE}${value.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                        showEditable={false} width={30} height={30}/>
                                </IonListHeader>
                            </IonItem>
                        )))}
                    </IonList>
                )}
                {selectedLeaderboard === 'expenses' && (
                    <IonList>
                        {expenses.map(((value: any, index: number) => (
                            <IonItem key={index}>
                                <IonListHeader>
                                    <IonLabel class="ion-text-center" className="ion-card-title">
                                        <span className="large-medal">
                            {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : ""}
                        </span>
                                        {value.name + " heeft € " + value.data + ",- uitgegeven"}
                                    </IonLabel>
                                    <ProfilePicture
                                        profilePicUrl={value.profilePictureUrl !== undefined ? `${process.env.REACT_APP_STORAGE}${value.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                        showEditable={false} width={30} height={30}/>
                                </IonListHeader>
                            </IonItem>
                        )))}
                    </IonList>
                )}
                {selectedLeaderboard === 'pukes' && (
                    <IonList>
                        {pukes.map(((value: any, index: number) => (
                            <IonItem key={index}>
                                <IonListHeader>
                                    <IonLabel class="ion-text-center" className="ion-card-title">
                                        <span className="large-medal">
                            {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : ""}
                        </span>
                                        {value.name + " heeft " + value.data + " keer gekotst"}
                                    </IonLabel>
                                    <ProfilePicture
                                        profilePicUrl={value.profilePictureUrl !== undefined ? `${process.env.REACT_APP_STORAGE}${value.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                        showEditable={false} width={30} height={30}/>
                                </IonListHeader>
                            </IonItem>
                        )))}
                    </IonList>

                )}
                {selectedLeaderboard === 'dertigen' && (

                    loading ? (
                            <div className={'loading-spinner-container'}>
                                <LoadingSpinner></LoadingSpinner>
                            </div>
                        ) :
                        (
                            <>
                                <IonRadioGroup class={'radio-button-group'} value={selectedDertigenLeaderboard} onIonChange={e => changeDertigenLeaderboard(e.detail.value)}>
                                    <IonItem button onClick={() => changeDertigenLeaderboard('dertigen-record')}>
                                        <IonLabel>Record</IonLabel>
                                        <IonRadio slot="start" value="dertigen-record"/>
                                    </IonItem>
                                    <IonItem button onClick={() => changeDertigenLeaderboard('doubler')}>
                                        <IonLabel>Verdubbelaars</IonLabel>
                                        <IonRadio slot="start" value="doubler"/>
                                    </IonItem>
                                    <IonItem button onClick={() => changeDertigenLeaderboard('stripe-goal')}>
                                        <IonLabel>Strependoel</IonLabel>
                                        <IonRadio slot="start" value="stripe-goal"/>
                                    </IonItem>
                                </IonRadioGroup>
                                <IonList>
                                    {dertigenRankings.map(((value: any, index: number) => (
                                        <IonItem key={index}>
                                            <IonListHeader>
                                                <IonLabel class="ion-text-center" className="ion-card-title">
                                        <span className="large-medal">
                            {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : ""}
                        </span>
                                                    {selectedDertigenLeaderboard === 'stripe-goal' && (
                                                        value.name + " heeft " + value.data + "% van zijn doelen gehaald"
                                                    )}
                                                    {selectedDertigenLeaderboard === 'doubler' && (
                                                        value.name + " heeft " + value.data + " verdubbelaars gegooid"
                                                    )}
                                                    {selectedDertigenLeaderboard === 'dertigen-record' && (
                                                        value.name + " zijn record is " + value.data + " strepen"
                                                    )}
                                                </IonLabel>
                                                <ProfilePicture
                                                    profilePicUrl={value.profilePictureUrl !== undefined ? `${process.env.REACT_APP_STORAGE}${value.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                                    showEditable={false} width={30} height={30}/>
                                            </IonListHeader>
                                        </IonItem>
                                    )))}
                                </IonList>
                            </>
                        )
                )}
            </IonContent>
        </IonPage>
    );
};

export default Ranglijsten;
