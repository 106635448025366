import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useEffect, useState} from "react";
import './DertigenList.css';
import {useParams} from "react-router-dom";
import createAxiosInstance from "../services/AxiosInstance";

import './DertigenScore.css'
import {useToast} from "../Utils/useToasts";

const DertigenScore: React.FC = (props) => {

        const [amountStrepen, setAmountStrepen] = useState<any>('')
        const [player, setPlayer] = useState<any>({})
        const [players, setPlayers] = useState<any>([])
        const {lobbyId} = useParams<{ lobbyId?: string }>();
        const {playerId} = useParams<{ playerId?: string }>();
        const axios = createAxiosInstance();
        const showToasts = useToast();
        const [selectedOption, setSelectedOption] = useState<string>('');

        const handleKeyDown = (event: any) => {
            if (event.key === 'Enter' && amountStrepen !== '') {
                axios.patch(`/dertigen/move/${lobbyId}/${playerId}`,
                    {
                        deltaStripes: Number(amountStrepen),
                        deltaGulps: 0
                    })
                    .then(res => {
                        showToasts(`Strepen: ${amountStrepen} is toegevoegd!`, "success")
                        setAmountStrepen('')
                        setPlayer(res.data)
                        event.target.blur()
                    }).catch(err => {
                    if (err.response.status === 405) {
                        showToasts('Je hebt geen toestemming', "danger")
                    } else {
                        showToasts('Er is iets mis gegaan', "danger")
                    }
                })
            }
        }

        const addStripes = (amountOfStripes: number) => {
            axios.patch(`/dertigen/move/${lobbyId}/${playerId}`,
                {
                    deltaStripes: Number(amountOfStripes),
                    deltaGulps: 0
                })
                .then(res => {
                    showToasts(`Strepen: ${amountOfStripes} is toegevoegd!`, "success")
                    setAmountStrepen('')
                    setPlayer(res.data)
                }).catch(err => {
                if (err.response.status === 405) {
                    showToasts('Je hebt geen toestemming', "danger")
                } else {
                    showToasts('Er is iets mis gegaan', "danger")
                }
            })
        }
        const fetchPeronalPlayerData = () => {
            axios.get(`/dertigen/${lobbyId}/${playerId}`)
                .then(res => {
                    setPlayers(res.data.players)
                    setPlayer(res.data)
                }).catch(error => {
                console.error(error);
            })
        }

        useEffect(() => {
            fetchPeronalPlayerData()
        }, [])

        const drankSomething = (amount: number) => {
            if (player.stripes > 0) {
                if (selectedOption === '') {
                    axios.patch(`/dertigen/move/${lobbyId}/${playerId}`,
                        {
                            deltaStripes: -amount,
                            deltaGulps: amount
                        })
                        .then(res => {
                            setPlayer(res.data)
                            showToasts(`Je hebt ${amount} strepen gedronken`, "success")
                        }).catch(err => {
                        console.log(err.response.status)
                        if (err.response.status === 405) {
                            showToasts('Je hebt geen toestemming', "danger")
                        } else {
                            showToasts('Er is iets mis gegaan', "danger")
                        }
                    })
                } else {
                    axios.patch(`dertigen/help-friend/${lobbyId}/${playerId}/${selectedOption}`,
                        {amountOfGulps: Number(amount)})
                        .then(res => {
                            showToasts(`Een friend heeft geholpen met ${amount} slokjes!`, "success")
                            setAmountStrepen('')
                            setPlayer(res.data)
                        }).catch(err => {
                        if (err.response.status === 405) {
                            showToasts('Je hebt geen toestemming', "danger")
                        } else {
                            showToasts('Er is iets mis gegaan', "danger")
                        }
                    });
                }
            } else {
                showToasts("Deze speler heeft 0 strepen", "danger")
            }
        }

        const doubler = () => {
            axios.patch(`/dertigen/double/${lobbyId}/${playerId}`)
                .then(() => {
                    showToasts("Je hebt de anderen verdubbeld!", "success")
                }).catch(err => {
                if (err.response.status === 405) {
                    showToasts('Je hebt geen toestemming', "danger")
                } else {
                    showToasts('Er is iets mis gegaan', "danger")
                }
            })
        }

        const handleSelectChange = (event: CustomEvent) => {
            setSelectedOption(event.detail.value);
        };

        const handleCheckboxChange = (event: CustomEvent) => {
            if (!event.detail.checked) {
                setSelectedOption('');
            }
        };


        return (
            <IonPage id='dertigen-list'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton/>
                        </IonButtons>
                        <IonTitle> {player.player} </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonCard>
                        <IonCardHeader>{player.stripes} strepen!</IonCardHeader>
                    </IonCard>
                    <IonRow class='stripes-button-box'>
                        <IonButton className='add-stripes-button' onClick={() => addStripes(5)}>+5 </IonButton>
                        <IonButton className='add-stripes-button' onClick={() => addStripes(10)}>+10</IonButton>
                        <IonButton className='add-stripes-button' onClick={() => addStripes(15)}>+15</IonButton>
                        <IonButton className='add-stripes-button' onClick={() => addStripes(20)}>+20</IonButton>
                        <IonButton className='add-stripes-button' onClick={() => addStripes(50)}>+50</IonButton>
                    </IonRow>
                    <IonGrid>
                        <IonRow class="ion-color-success">
                            <IonCol class="ion-text-center">
                                <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`} onClick={() => drankSomething(10)}>Halve bak</IonButton>
                            </IonCol>
                            <IonCol class="ion-text-center">
                                <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`} onClick={() => drankSomething(20)}>Hele bak</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="ion-text-center">
                                <IonButton className={'normal'} onClick={() => doubler()}>Verdubbelaar</IonButton>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol class="ion-text-center">
                                <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`} onClick={() => drankSomething(25)}>Duikboot</IonButton>
                            </IonCol>
                            <IonCol class="ion-text-center">
                                <IonButton className={`${selectedOption !== '' ? 'green' : 'normal'}`} onClick={() => drankSomething(30)}>Rietadt</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonItem>
                            <IonCheckbox slot="start" checked={selectedOption !== ''} onIonChange={handleCheckboxChange} disabled={selectedOption === ''}/>
                            <IonSelect placeholder="Wie drinkt er voor deze persoon?" value={selectedOption}
                                       onIonChange={handleSelectChange} >
                                {players.filter((value: any) => value.id !== player.playerId).map((value: any, key: any) => (
                                    <IonSelectOption class='ion-select-option-class' key={key} value={value.id}>{value.name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonInput id='strepen-input' type="number" placeholder="Strepen toevoegen"
                                      value={amountStrepen}
                                      onIonInput={(e: any) => setAmountStrepen(e.target.value)}
                                      onKeyPress={handleKeyDown}/>
                        </IonItem>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
;

export default DertigenScore;
