import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import "./AdminOverview.css"
import createAxiosInstance from "../services/AxiosInstance";
import Controller from "./Controller";
import {settingsOutline} from "ionicons/icons";
import ProfilePicture from "../components/ProfilePicture";
import CurrencyDisplay from "../components/CurrencyDisplay";

const AdminOverview: React.FC = () => {

    const [users, setUsers] = useState<any>([])
    const [selectedRoles, setSelectedRoles] = useState<string[]>(["user", 'regular-guest']);
    const axios = createAxiosInstance();
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [totalBalance, setTotalBalance] = useState<number>();

    const roles = ["user", "regular-guest", "dertigen-user"];

    const fetchUsers = () => {
        axios.get(`/player`)
            .then(res => {
                console.log(res.data)
                setUsers(res.data.players)
                setTotalBalance(Number(res.data.totalBalance))
            })
    }

    const handleRoleChange = (role: string) => {
        const updatedRoles = selectedRoles.includes(role)
            ? selectedRoles.filter(r => r !== role)
            : [...selectedRoles, role];
        setSelectedRoles(updatedRoles);
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    }

    const getRoleClass = (roles: string[]) => {
        return roles.length > 0 ? `role-${roles[0]}` : '';
    };

    useEffect(() => {
        fetchUsers();
    }, [])


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Admin overzicht</IonTitle>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonButton slot="end" onClick={toggleFilterVisibility}>
                        <IonIcon icon={settingsOutline} slot="icon-only"/>
                    </IonButton>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {isFilterVisible && (
                    <div className="sticky-checkboxes">

                        <IonLabel>Filter op rol</IonLabel>
                        <div className={'role-container'}>
                            {roles.map(role => (
                                <IonItem key={role}>
                                    <IonLabel>{role}</IonLabel>
                                    <IonCheckbox
                                        checked={selectedRoles.includes(role)}
                                        onIonChange={() => handleRoleChange(role)}
                                    />
                                </IonItem>
                            ))}
                        </div>
                    </div>
                )}
                {totalBalance && (
                    <div className="total-balance-container">
                        <IonCard>
                            <IonCardHeader>
                                <IonCardSubtitle>Keet balans</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <CurrencyDisplay amount={totalBalance}/>
                            </IonCardContent>
                        </IonCard>
                    </div>
                )}
                <IonList class="admin-list">
                    {users.filter((user: any) => user.roles.some((role: string) => selectedRoles.includes(role)))
                        .map((value: any, index: number) => (
                            <IonItem routerLink={`/admin/${value._id}`}
                                     className={`admin-list-item ${getRoleClass(value.roles)}`}
                                     key={index}>
                                <IonListHeader>
                                    <ProfilePicture
                                        profilePicUrl={(value.profilePictureUrl?.length !== 0 && value.profilePictureUrl) ? `${process.env.REACT_APP_STORAGE}${value.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                        showEditable={false} width={25} height={25}/>
                                    <IonLabel class='admin-label' className="ion-card-title">
                                        {value.name}
                                    </IonLabel>
                                </IonListHeader>
                            </IonItem>
                        ))
                    }
                </IonList>
                <div className={'fridge-controller-container'}>
                    <Controller/>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default AdminOverview;
