// src/components/DiceContainer.tsx
import React, {useState} from 'react';
import Dice from './Dice';
import './DiceContainer.css'
import {IonButton, IonLabel} from "@ionic/react";

const DiceContainer: React.FC = () => {
    const [diceValues, setDiceValues] = useState([1, 1, 1, 1, 1, 1]);
    const [isFrozen, setIsFrozen] = useState([false, false, false, false, false, false]);

    const rollDice = () => {
        if (isFrozen.some((frozen) => !frozen)) {
            const newDiceValues = diceValues.map((value, index) =>
                isFrozen[index] ? value : Math.floor(Math.random() * 6) + 1
            );
            setDiceValues(newDiceValues);
        }
    };

    const toggleFreeze = (index: number) => {
        const newIsFrozen = [...isFrozen];
        newIsFrozen[index] = !newIsFrozen[index];
        setIsFrozen(newIsFrozen);
    };

    const resetDices = () => {
        // Reset the isFrozen state to all false
        setIsFrozen([false, false, false, false, false, false]);
    };

    const totalScore = diceValues.reduce((acc, value) => acc + value, 0);

    return (
        <div>
            <div className={'dice-center'}>
                <IonLabel>
                    <h1>
                        Gegooid: {totalScore}
                    </h1>
                </IonLabel>
            </div>
            <div className="dice-container">
                {diceValues.map((value, index) => (
                    <Dice
                        key={index}
                        value={value}
                        isFrozen={isFrozen[index]}
                        onClick={() => toggleFreeze(index)}
                    />
                ))}
            </div>
            <div className={'dice-center'}>
                <IonButton className={'throw-button'} onClick={rollDice}>Gooi</IonButton>
                <IonButton className={'throw-button'} onClick={resetDices}>Reset</IonButton>
            </div>
        </div>
    );
};

export default DiceContainer;
