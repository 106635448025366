import {IonButton, IonContent, IonIcon, IonItem, IonLabel, IonModal} from '@ionic/react';
import React, {useState} from 'react';
import './AddTransactionPopOver.css';
import {cardOutline} from "ionicons/icons";
import CurrencyInput from "./CurrencyInput";

const AddTransactionPopover: React.FC<{
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (amount: number) => void
}> = ({isOpen, onClose, onSubmit}) => {
    const [amount, setAmount] = useState<number | null>(null);

    const handleSubmit = () => {
        if (amount !== null) {
            onSubmit(amount);
            onClose();
        }
    };

    return (
        <IonModal trigger={'open-modal'} className={'transaction-modal'} isOpen={isOpen} onDidDismiss={onClose}
                  initialBreakpoint={1} breakpoints={[0, 1]}>
            <IonContent className={'modal-content'}>
                <div className="icon-container">
                    <IonIcon icon={cardOutline} className="large-icon"/>
                </div>

                <IonItem className="custom-input-item">
                    <IonLabel position="stacked" className="custom-label">Saldo opwaarderen</IonLabel>
                    <CurrencyInput onValueChange={setAmount} value={amount ?? 0} placeholder={'Voer bedrag in'}/>
                </IonItem>
                <IonButton className={'submit-button'} onClick={handleSubmit}>Geld overmaken</IonButton>
            </IonContent>
        </IonModal>
    );
};

export default AddTransactionPopover;
