import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonAlert,
    useIonPicker
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import './DertigenList.css';
import {useHistory, useParams} from 'react-router-dom'
import io, {Socket} from "socket.io-client";
import createAxiosInstance from "../services/AxiosInstance";
import {User} from "../model/User";
import {DertigenEvent} from "../model/DertigenEvent";
import {dice, helpCircleSharp, scanCircleOutline, settingsOutline} from "ionicons/icons";
import {useToast} from "../Utils/useToasts";
import DiceContainer from "../components/DiceContainer";
import ProfilePicture from "../components/ProfilePicture";
import DertigenMoveInput from "./DertigenMoveInput";
import ReorderPlayers from "../components/ReorderPlayers";
import DrinkStripesPopOver from "../components/DrinkStripesPopOver";
import {QrData, QrReader} from "react-qr-reader";


const DertigenList: React.FC = () => {

    const [lobby, setLobby] = useState<any>({});
    const [person, setPerson] = useState<User>();
    const [players, setPlayers] = useState<any>([])
    const {lobbyId} = useParams<{ lobbyId?: string }>();
    const showToasts = useToast();
    const [presentPicker] = useIonPicker();
    const [presentAlert] = useIonAlert();
    const axios = createAxiosInstance();
    const [showLastMove, setShowLastMove] = useState(true);
    const [clockWise, setClockWise] = useState<boolean>(true);
    const [showPlayDirection, setShowPlayDirection] = useState<boolean>(true);
    const [showDices, setShowDices] = useState<boolean>(false);
    const [showBuyedOutPlayers, setShowBuyedOutPlayers] = useState(true);
    const [showProfilePictures, setShowProfilePictures] = useState(true);
    const [lastMove, setLastMove] = useState<DertigenEvent>();
    const [showDialog, setShowDialog] = useState(false);
    const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
    const [previousPlayers, setPreviousPlayers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPlayerOnTurn, setCurrentPlayerOnTurn] = useState<any>({});
    const [throwForPlayerId, setThrowForPlayerId] = useState<string>('');
    const history = useHistory();
    const [isReorderVisible, setIsReorderVisible] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [popoverEvent, setPopoverEvent] = useState();
    const [playerToOpenPopoverFor, setPlayerToOpenPopoverFor] = useState<string>('');
    const [showQrReader, setShowQrReader] = useState<boolean>(false);
    const socketRef = useRef<Socket | null>(null);
    const [scannerContraints, setScannerContraints] = useState<MediaTrackConstraints>({facingMode: 'environment'});

    const toggleReorderVisibility = () => {
        setIsReorderVisible(!isReorderVisible);
    };

    const openPopover = (e: any, playerId: string) => {
        setPopoverEvent(e);
        setPlayerToOpenPopoverFor(playerId);
        setShowPopover(true);
    };

    const showLastMoveToggle = () => {
        setShowLastMove(!showLastMove);
    }

    const showBuyedOutPlayersToggle = () => {
        setShowBuyedOutPlayers(!showBuyedOutPlayers);
    }

    const showProfilePicturesToggle = () => {
        setShowProfilePictures(!showProfilePictures);
    }

    const addNewPlayerToLobby = (name: string) => {
        axios.post(`/player`, {
            name: name
        })
            .then(res => {
                axios.patch(`/dertigen/${lobbyId}/join/${res.data._id}`)
                    .then(() => {
                        showToasts('Speler toegevoegd!', 'success')
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                            showToasts('Er bestaat al een speler met deze naam!', 'danger')
                        }
                    })
            })
    }

    const addExistingPlayerToLobby = (player: any) => {
        axios.patch(`/dertigen/${lobbyId}/join/${player._id}`)
            .then(() => {
                showToasts('Speler toegevoegd!', 'success')
            });
    }

    const updateOwnerOfLobby = (ownerId: string) => {
        axios.patch(`/dertigen/${lobbyId}`, {
            owner: ownerId
        })
            .then(() => {
                showToasts('Streper succesvol aangepast!', 'success')
            })
    }


    function checkIfPlayDirectionHasChanged(players: any[]) {
        let gulpsDivided = Math.floor(players[0]?.gulps / 200);
        if ((gulpsDivided % 2) === 0) {
            setClockWise(true);
        } else {
            setClockWise(false)
        }
    }

    useEffect(() => {
        socketRef.current = io(`${process.env.REACT_APP_SOCKET}`);

        const fetchCurrentLobby = () => {
            axios.get(`/dertigen/${lobbyId}`)
                .then(res => {
                    setLobby(res.data.lobby)
                    let sortedPlayers = res.data.players.sort((a: any, b: any) => {
                        return b.gulps - a.gulps;
                    })
                    setPlayers(sortedPlayers)
                    setLastMove(res.data.mostRecentMove)
                    checkIfPlayDirectionHasChanged(sortedPlayers)
                })
        }

        const fetchPreviousPlayedPlayers = () => {
            axios.get(`/player/guest-players/new`)
                .then(res => {
                    console.log(res)
                    setPreviousPlayers(res.data.players)
                })
        }

        const fetchPeronalData = () => {
            axios.get('/player/personal-data/header')
                .then(res => {
                    setPerson(res.data)
                }).catch(error => {
                console.error(error);
            })
        }

        const fetchCurrentPlayerOnTurn = () => {
            axios.get(`/dertigen/${lobbyId}/lobby-state/current-turn`)
                .then(res => {
                    setCurrentPlayerOnTurn(res.data.currentPlayer)
                    setThrowForPlayerId(res.data.playerIdToThrowFor)
                }).catch(error => {
                console.error(error);
            })
        }

        socketRef.current.on("connect", () => {
            if (socketRef.current !== null) socketRef.current.emit('lobby', lobbyId)
        })

        socketRef.current.on('update', () => {
            fetchCurrentLobby()
            fetchPeronalData()
            fetchCurrentPlayerOnTurn()
        })
        fetchCurrentLobby()
        fetchPeronalData()
        fetchPreviousPlayedPlayers()
        fetchCurrentPlayerOnTurn()
        return () => {
            if (socketRef.current !== null) {
                socketRef.current.disconnect()
                socketRef.current.off('connect');
                socketRef.current.off("disconnect")
                socketRef.current.off('update');
                socketRef.current.off('gulps')
            }
        }
    }, [])

    const buyBackIn = (amountOfStripes: number) => {
        axios.patch(`/dertigen/buy-back/${lobbyId}/${person?.id}`,
            {
                deltaStripes: Number(10 - amountOfStripes),
                deltaGulps: 0
            })
            .catch(() => {
                showToasts('Er is iets mis gegaan', 'danger')
            })
    }

    const openMasterPicker = () => {
        presentPicker({
            buttons: [
                {
                    text: 'Bevestig',
                    handler: (selected) => {
                        updateOwnerOfLobby(selected.spelers.value)
                    },
                },
            ],
            columns: [{
                name: "spelers",
                options: getColumnOptions()
            }
            ]
        })
    }

    function getColumnOptions() {
        let options: any[] = [];
        console.log(players)
        players
            .filter((val: any) => (val.roles.includes('user') || val.roles.includes('dertigen-user')) && val.playerId !== person?.id)
            .forEach((value: any, key: any) => {
                options.push({text: value.player, value: value.playerId});
            });
        return options;
    }

    function addPlayer() {
        presentAlert({
            header: 'Geef de naam in',
            buttons: [
                {
                    text: 'OK',
                    handler: (givenName) => {
                        addNewPlayerToLobby(givenName.name);
                    }
                }],
            inputs: [
                {
                    name: "name",
                    type: "text",
                    placeholder: "Naam van speler",
                }
            ],
        })
    }

    function performDeleteOnLastMove() {
        axios.delete(`/dertigen/move/${lobbyId}/${lastMove?.moveId}`)
            .then(res => {
                showToasts(res.data.message, 'success')
            })
    }

    const handleAddPlayer = (player: User) => {
        setShowAddPlayerModal(false);
        addExistingPlayerToLobby(player);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredPlayers = previousPlayers?.filter(player =>
        player.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const navigateToStatutes = () => {
        history.push(`/statutes`)
    }

    const handleClick = (event: MouseEvent, playerId: string) => {
        if (!isOwnerAndMachineControlled) {
            history.push(`/dertigen/${lobbyId}/score/${playerId}`);
        } else {
            openPopover(event, playerId);
        }
    };

    const handleScan = (data: QrData | null) => {
        if (data) {
            const sendLobbyInfoToDashboard = {
                lobbyId: lobbyId,
                dashboardSocketId: data.text
            }
            if (socketRef.current !== null) socketRef.current.emit('lobbyToDashboard', sendLobbyInfoToDashboard)
            setShowQrReader(false)
        }
    }

    const renderLowerPart = () => {
        return (
            <>
                <IonFab vertical="bottom" horizontal="start" slot="fixed">
                    <IonFabButton onClick={() => navigateToStatutes()}>
                        <IonIcon icon={helpCircleSharp}/>
                    </IonFabButton>
                </IonFab>
                <IonFab color={"purple"} vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => setShowQrReader(!showQrReader)}>
                        <IonIcon icon={scanCircleOutline}/>
                    </IonFabButton>
                </IonFab>
                {showLastMove &&
                    <IonCard className="last-move-card">
                        <IonCardHeader className="ion-text-center">
                            <IonCardTitle class="last-move-card-title">Laatste zet</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="dertigen-label">
                            {lastMove?.isDrinkingMove && (
                                <h2 className="last-move-card-title">{lastMove?.player} heeft {lastMove?.gulps} strepen
                                    gedronken
                                </h2>
                            )}
                            {lastMove?.doubler && (
                                <h2 className="last-move-card-title">{lastMove?.player} heeft iedereen
                                    verdubbeld!
                                </h2>
                            )}
                            {(!lastMove?.doubler && !lastMove?.isDrinkingMove) && (
                                < h2
                                    className="last-move-card-title">{lastMove?.player} heeft {lastMove?.stripes} strepen
                                    erbij gekregen
                                </h2>
                            )}
                            <IonButton color="danger" onClick={() => performDeleteOnLastMove()}>
                                Verwijder
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                }
                {
                    showBuyedOutPlayers ?
                        <IonList>
                            {players.map((value: any, key: any) => (
                                <IonCard
                                    className={`dertigen-card${value.stripes > 50 ? "-illegal" : value.stripes < 1 ? "-buyed-out" : ""} ion-text-center`}
                                    key={key} onClick={(e) => handleClick(e.nativeEvent, value.playerId)}>
                                    <IonListHeader>
                                        <IonLabel class='dertigen-label' className="ion-card-title">
                                            {showProfilePictures && (
                                                <ProfilePicture
                                                    profilePicUrl={value.playerProfilePic !== undefined ? `${process.env.REACT_APP_STORAGE}${value.playerProfilePic}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                                    showEditable={false} width={30} height={30}/>
                                            )}
                                            <h1>
                                                {value.playerId === currentPlayerOnTurn.playerId && (
                                                    <IonIcon icon={dice} style={{marginRight: '15px'}}/>
                                                )}
                                                {value.player}
                                            </h1>
                                            <h1>{value.stripes} strepen</h1>
                                            <h1>{value.gulps} gedronken</h1>
                                        </IonLabel>
                                    </IonListHeader>
                                </IonCard>
                            ))}
                            {players.filter((player: any) => player.playerId === (person?.id) && player.stripes < 1).map((value: any, key: any) => (
                                <IonCard className='buy-in-card ion-text-center' key={key}>
                                    <IonButton onClick={() => buyBackIn(value.stripes)}>Opnieuw
                                        inkopen</IonButton>
                                </IonCard>
                            ))}
                        </IonList> :
                        <IonList>
                            {players.filter((player: any) => player.stripes > 0).map((value: any, key: any) => (
                                <IonCard
                                    className={`dertigen-card${value.stripes > 50 ? "-illegal" : ""} ion-text-center`}
                                    key={key} onClick={(e) => handleClick(e.nativeEvent, value.playerId)}>
                                    <IonListHeader>
                                        <IonLabel class='dertigen-label' className="ion-card-title">
                                            {showProfilePictures && (
                                                <ProfilePicture
                                                    profilePicUrl={value.playerProfilePic !== undefined ? `${process.env.REACT_APP_STORAGE}${value.playerProfilePic}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                                    showEditable={false} width={30} height={30}/>
                                            )}
                                            <h1>
                                                {value.playerId === currentPlayerOnTurn.playerId && (
                                                    <IonIcon icon={dice} style={{marginRight: '15px'}}/>
                                                )}
                                                {value.player}
                                            </h1>
                                            <h1>{value.stripes} strepen</h1>
                                            <h1>{value.gulps} gedronken</h1>
                                        </IonLabel>
                                    </IonListHeader>
                                </IonCard>
                            ))}
                            {players.filter((player: any) => player.playerId === (person?.id) && player.stripes < 1).map((value: any, key: any) => (
                                <IonCard className='buy-in-card ion-text-center' key={key}>
                                    <IonButton onClick={() => buyBackIn(value.stripes)}>Opnieuw
                                        inkopen</IonButton>
                                </IonCard>
                            ))}
                        </IonList>
                }
                {
                    showPlayDirection &&
                    <IonLabel class={'play-direction-label'}>
                        <h1>
                            {clockWise ? "Met de klok mee!" : "Tegen de klok in!"}
                        </h1>
                    </IonLabel>
                }
                {
                    showDices &&
                    <IonCard className={'dice-container-div'}>
                        <DiceContainer/>
                    </IonCard>
                }
                {
                    showPlayDirection &&
                    <IonModal isOpen={showAddPlayerModal}>
                        <IonContent>
                            <IonInput
                                value={searchTerm}
                                placeholder="Zoek speler"
                                onIonInput={e => handleSearchChange(e as any)}
                            />
                            <IonList>
                                {filteredPlayers?.map((player, index) => (
                                    <IonItem key={index} button onClick={() => handleAddPlayer(player)}>
                                        {player.name}
                                    </IonItem>
                                ))}
                            </IonList>
                            <div className={'floating-button-container'}>

                                <IonButton onClick={addPlayer}>Nieuwe speler</IonButton>
                                <IonButton onClick={() => setShowAddPlayerModal(false)}>Sluit</IonButton>
                            </div>
                        </IonContent>
                    </IonModal>

                }
                <DrinkStripesPopOver isOpen={showPopover} onClose={() => setShowPopover(false)}
                                     playerId={playerToOpenPopoverFor} lobbyId={lobbyId}/>
            </>
        )
    }

    const switchCamera = () => {
        setScannerContraints(prevConstraints => ({
            ...prevConstraints,
            facingMode: prevConstraints.facingMode === 'user' ? 'environment' : 'user'
        }));
    };

    const isOwnerAndMachineControlled = person?.id === lobby?.owner && lobby?.isMachineControlledLobby;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Dertigen in {lobby.name}</IonTitle>
                    {lobby?.owner === person?.id ?
                        <IonButtons slot="end">
                            <IonButton
                                onClick={() =>
                                    openMasterPicker()}>
                                Streper
                            </IonButton>
                            <IonButton onClick={() =>
                                setShowAddPlayerModal(true)}>
                                Toevoegen
                            </IonButton>
                        </IonButtons>
                        :
                        <p></p>
                    }
                    <IonButton slot="end" onClick={() => setShowDialog(true)}>
                        <IonIcon icon={settingsOutline} slot="icon-only"/>
                    </IonButton>
                    <IonModal isOpen={showDialog}>
                        <IonContent>
                            <IonList>
                                <IonItem>
                                    <IonLabel>Toon laatste zet</IonLabel>
                                    <IonToggle checked={showLastMove} onIonChange={() => showLastMoveToggle()}/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Uitgekochte spelers zichtbaar</IonLabel>
                                    <IonToggle checked={showBuyedOutPlayers}
                                               onIonChange={() => showBuyedOutPlayersToggle()}/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Speel richting zichtbaar</IonLabel>
                                    <IonToggle checked={showPlayDirection}
                                               onIonChange={() => setShowPlayDirection(!showPlayDirection)}/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Virtuele dobbelstenen</IonLabel>
                                    <IonToggle checked={showDices}
                                               onIonChange={() => setShowDices(!showDices)}/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Profiel foto's in beeld</IonLabel>
                                    <IonToggle checked={showProfilePictures}
                                               onIonChange={() => showProfilePicturesToggle()}/>
                                </IonItem>
                            </IonList>
                            <IonButton onClick={() => setShowDialog(false)}>Sluit</IonButton>
                        </IonContent>
                    </IonModal>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div>
                    {isOwnerAndMachineControlled && (
                        <>
                            <div className={"move-input-container"}>
                                <DertigenMoveInput currentPlayer={currentPlayerOnTurn} lobbyId={lobbyId}
                                                   throwForPlayerId={throwForPlayerId}/>
                                {isReorderVisible && <ReorderPlayers lobbyId={lobbyId}/>}
                                <div className={'reorder-button-container'}>
                                    <IonButton onClick={toggleReorderVisibility} className="toggle-reorder-btn">
                                        {isReorderVisible ? '↑' : '↓'}
                                    </IonButton>
                                </div>
                            </div>
                            <hr className={'seperator'}/>
                        </>
                    )}
                </div>
                {renderLowerPart()}
                <IonPopover isOpen={showQrReader}>
                    <div className="popover-content">
                        <h3 className="popover-title">Scan de dashboard QR</h3>
                        <QrReader
                            onResult={handleScan}
                            onError={(err) => console.error(err)}
                            style={{width: '100%'}}
                            resolution={1200}
                            showViewFinder={true}
                            constraints={scannerContraints}
                        ></QrReader>
                        <IonButton onClick={switchCamera}>
                            Draai Camera
                        </IonButton>
                        <p className="popover-hint">Scan de QR code op het dashboard om deze lobby in de grafiek te
                            krijgen.</p>
                    </div>
                </IonPopover>
            </IonContent>
        </IonPage>
    )
}

export default DertigenList;
