import {useIonToast} from "@ionic/react";

import './useToasts.css'

export const useToast = () => {
    const [present, dismiss] = useIonToast();

    const showToasts = (message: string, color: string) => {
        present({
            message: message,
            duration: 1000,
            position: "top",
            color: color,
            cssClass: "toast-top-right"
        })
    }
    return showToasts;
}
