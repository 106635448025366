import {
    IonApp,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    setupIonicReact
} from '@ionic/react';
import {Route, useHistory, useLocation} from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {
    bookSharp,
    cardOutline,
    flame,
    gameController,
    homeOutline,
    logOut,
    person,
    power,
    trophy
} from "ionicons/icons";
import PersonalStats from "./pages/PersonalStats";
import React, {useEffect, useState} from "react";
import Login from "./pages/Login";
import DertigenList from "./pages/DertigenList";
import DertigenScore from "./pages/DertigenScore";
import DertigenLobbyList from "./pages/DertigenLobbyList";
import AdminOverview from "./pages/AdminOverview";
import Ranglijsten from "./pages/Ranglijsten";

import './App.css'
import AdminPersonalOverview from "./pages/AdminPersonalOverview";
import {User} from "./model/User";
import createAxiosInstance from "./services/AxiosInstance";
import PrivateRoute from "./components/PrivateRoute";
import Transactions from "./pages/Transactions";
import Register from "./pages/Register";
import PresenceList from "./pages/PresenceList";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import TransactionSuccesful from "./pages/TransactionSuccesful";
import TransactionUnsuccessful from "./pages/TransactionUnsuccessful";
import ProfilePicture from "./components/ProfilePicture";
import DertigenStatutes from "./pages/DertigenStatutes";
import ResetPasswordEmailSend from "./pages/ResetPasswordEmailSend";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage";
import ProfilePage from "./pages/ProfilePage";
import FirewallSettings from "./pages/FirewallSettings";
import MqttController from "./pages/MqttController";
import MqttSceneOverview from "./pages/scenes/MqttSceneOverview";

setupIonicReact();

const App: React.FC = () => {
    const [user, setUser] = useState<User>();
    const axios = createAxiosInstance();

    const [activePage, setActivePage] = useState<string>('index');

    const location = useLocation();
    const history = useHistory()

    const pathToStateMap: { [key: string]: string } = {
        '/': 'index',
        '/dertigen': 'dertigen',
        '/transactions': 'transactions',
        '/ranglijsten': 'ranking',
        '/admin': 'admin',
        '/statutes': 'statuten',
        '/account-settings': 'account-settings',
        '/firewall-rules': 'firewall-rules',
        '/mqtt-controller': 'mqtt-controller',
        '/scenes': 'scenes'
    };

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);

        const firstSegment = pathSegments.length > 0 ? `/${pathSegments[0]}` : 'index';

        setActivePage(pathToStateMap[firstSegment] || 'index');
    }, [location]);

    function fetchPersonalData() {
        axios.get('/player/personal-data/header')
            .then(response => {
                setUser(response.data)
            }).catch(error => {
            console.error(error)
        })
    }

    useEffect(() => {
        fetchPersonalData()
    }, [])

    function removeJwtTokenAndRedirect() {
        localStorage.removeItem('jwt');
        redirectTo('/login')
    }

    function closeAppMenu() {
        const menu = document.querySelector('ion-menu');
        menu?.close();
    }

    function redirectTo(path: string) {
        history.push(path)
        closeAppMenu();
    }

    return (
        <IonApp>
            <IonRouterOutlet id="main-content">
                <Route exact path="/login">
                    <Login/>
                </Route>
                <Route exact path="/register">
                    <Register/>
                </Route>
                <Route exact path="/reset-password/:token">
                    <ResetPasswordPage/>
                </Route>
                <Route exact path="/reset-password-email-send">
                    <ResetPasswordEmailSend/>
                </Route>
                <Route exact path="/request-password-reset">
                    <RequestPasswordResetPage/>
                </Route>
                <PrivateRoute exact path="/welkom"
                              component={PersonalStats}/>
                <PrivateRoute exact path="/presence/:userId"
                              component={PresenceList}/>
                <PrivateRoute exact path="/dertigen"
                              component={DertigenLobbyList}/>
                <PrivateRoute exact path="/dertigen/:lobbyId"
                              component={DertigenList}/>
                <PrivateRoute exact path="/dertigen/:lobbyId/score/:playerId"
                              component={DertigenScore}/>
                <PrivateRoute exact path="/"
                              component={PersonalStats}/>
                <PrivateRoute exact path="/admin"
                              component={AdminOverview}/>
                <PrivateRoute exact path="/admin/:playerId"
                              component={AdminPersonalOverview}/>
                <PrivateRoute exact path="/transactions"
                              component={Transactions}/>
                <PrivateRoute exact path="/ranglijsten"
                              component={Ranglijsten}/>
                <PrivateRoute exact path="/successful_payment"
                              component={TransactionSuccesful}/>
                <PrivateRoute exact path="/cancelled_payment"
                              component={TransactionUnsuccessful}/>
                <PrivateRoute exact path="/statutes"
                              component={DertigenStatutes}/>
                <PrivateRoute exact path="/account-settings" component={ProfilePage}/>
                <PrivateRoute exact path="/firewall-rules" component={FirewallSettings}/>
                <PrivateRoute exact path="/iot-controller" component={MqttController}/>
                <PrivateRoute exact path="/scenes" component={MqttSceneOverview}/>
            </IonRouterOutlet>
            <IonMenu side="start" contentId="main-content">
                <IonHeader>
                    <IonToolbar>
                        <div className={'sidebar-toolbar'}>
                            <IonTitle>{user?.name}</IonTitle>
                            <ProfilePicture
                                profilePicUrl={(user?.profilePictureUrl?.length !== 0 && user?.profilePictureUrl) ? `${process.env.REACT_APP_STORAGE}${user.profilePictureUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                showEditable={false} width={30} height={30}/>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonContent class={'sidebar-content'}>
                    <IonList lines={'none'}>
                        <IonMenuToggle auto-hide="false">
                            <IonItem button onClick={() => redirectTo("/")} className={activePage === 'index' ? 'selected' : 'not-selected'}>
                                <IonIcon slot="start" icon={person}/>
                                <IonLabel>Info</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                        <IonMenuToggle auto-hide="false">
                            <IonItem button onClick={() => redirectTo("/dertigen")}
                                     className={activePage === 'dertigen' ? 'selected' : 'not-selected'}>
                                <IonIcon slot="start" icon={gameController}/>
                                <IonLabel>Dertigen</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                        {(user?.roles?.includes('user') || user?.roles?.includes('regular-guest')) && (
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/transactions")}
                                         className={activePage === 'transactions' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={cardOutline}/>
                                    <IonLabel>Transacties</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        )}
                        {(user?.roles?.includes('user') || user?.roles?.includes('regular-guest')) && (
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/ranglijsten")}
                                         className={activePage === 'ranking' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={trophy}/>
                                    <IonLabel>Ranglijsten</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        )}
                        {(user?.roles?.includes('user') || user?.roles?.includes('regular-guest')) && (
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/statutes")}
                                         className={activePage === 'statuten' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={bookSharp}/>
                                    <IonLabel>Dertigen statuten</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        )}
                        {(user?.roles?.includes('user') || user?.roles?.includes('regular-guest') || user?.roles?.includes("dertigen-user")) && (
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/account-settings")}
                                         className={activePage === 'account-settings' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={person}/>
                                    <IonLabel>Mijn account</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        )}
                        {(user?.roles?.includes('admin')) &&
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/iot-controller")}
                                         className={activePage === 'mqtt-controller' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={gameController}/>
                                    <IonLabel>Verlichting besturen</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        }
                        {(user?.roles?.includes('admin')) &&
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/scenes")}
                                         className={activePage === 'scenes' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={homeOutline }/>
                                    <IonLabel>Scene's beheren</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        }
                        {(user?.roles?.includes('admin')) &&
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/admin")}
                                         className={activePage === 'admin' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={power}/>
                                    <IonLabel>Admin</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        }
                        {(user?.roles?.includes('admin')) &&
                            <IonMenuToggle auto-hide="false">
                                <IonItem button onClick={() => redirectTo("/firewall-rules")}
                                         className={activePage === 'firewall-rules' ? 'selected' : 'not-selected'}>
                                    <IonIcon slot="start" icon={flame}/>
                                    <IonLabel>Database firewall</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        }
                        <div className={'logout-button-container'}>
                            <IonItem button onClick={() => removeJwtTokenAndRedirect()}>
                                <IonIcon slot="start" icon={logOut}/>
                                <IonLabel>Log uit</IonLabel>
                            </IonItem>
                        </div>
                    </IonList>
                </IonContent>
            </IonMenu>
        </IonApp>
    );
}

export default App;
