import React from 'react';
import './CurrencyDisplay.css';

interface CurrencyDisplayProps {
    amount: number;
}

const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({ amount }) => {
    // Ensure amount is a number
    if (typeof amount !== 'number' || isNaN(amount)) {
        return <div className="amount">Invalid amount</div>;
    }

    let fullAmount, decimal;

    if (Number.isInteger(amount)) {
        fullAmount = amount.toString();
        decimal = '00';
    } else {
        const amountString = amount.toFixed(2);
        [fullAmount, decimal] = amountString.split('.');
    }

    return (
        <div className="amount">
            € <span className="full-amount">{fullAmount}</span>,<span className="decimal-amount">{decimal}</span>
        </div>
    );
};

export default CurrencyDisplay;
