import React, {useCallback, useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";
import EmailInput from "../components/EmailInput";

const ProfilePage: React.FC = () => {
    const [id, setId] = useState<string>('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState<boolean>(true);
    const axios = createAxiosInstance();
    const showToasts = useToast();

    const fetchPersonalInformation = async () => {
        axios.get('/player/account-settings/personal-information')
            .then(res => {
                setName(res.data.name);
                setEmail(res.data.emailAddress);
                setId(res.data.id);
            })
    }

    const handleSave = () => {
        if (name.length === 0 || email.length === 0) {
            showToasts('Niet alle velden zijn ingevuld', 'danger');
            return;
        }
        if (name.length < 4) {
            showToasts('Naam te kort', 'danger');
            return;
        }
        if (!emailValid) {
            showToasts('Vul een geldig e-mailadres in.', 'danger');
            return;
        }

        axios.post(`/player/account-settings/personal-information/${id}`, {
            name: name,
            emailAddress: email
        }).then(res => {
            showToasts('Profiel opgeslagen', 'success');
        }).catch(err => {
            if (err.response.status === 400) {
                showToasts('Er is een duplicaat gevonden, probeer een andere naam of email', 'danger')
                return;
            } else {
                showToasts('Er is iets mis gegaan', 'danger');
            }
        })
    }

    const handleEmailChange = useCallback((newEmail: string, isValid: boolean) => {
        setEmail(newEmail);
        setEmailValid(isValid);
    }, []);

    useEffect(() => {
        fetchPersonalInformation();
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Profiel Instellingen</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonList>
                    <IonItem>
                        <IonLabel position="floating">Naam</IonLabel>
                        <IonInput value={name} onIonInput={e => setName(e.detail.value!)}/>
                    </IonItem>
                    <EmailInput onEmailChange={handleEmailChange} initialEmail={email}/>
                </IonList>
                <IonButton expand="block" onClick={handleSave}>Wijzigingen opslaan</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default ProfilePage;
