import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPopover,
    IonTitle,
    IonToggle
} from '@ionic/react';
import './MqttSceneBuilderComponent.css'; // Add a CSS file for custom styling
import createAxiosInstance from "../../../services/AxiosInstance";
import Scene from "../Scene";
import CronTimeBuilderComponent from "./CronTimeBuilderComponent";
import {useToast} from "../../../Utils/useToasts";
import {AxiosError} from "axios";

const MqttSceneBuilderComponent: React.FC = () => {
    const [name, setName] = useState('');
    const [cronTime, setCronTime] = useState('* * * * *');
    const [action, setAction] = useState('');
    const [mqttTopic, setMqttTopic] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [showPopover, setShowPopover] = useState(false);
    const [scenes, setScenes] = useState<Scene[]>([]);
    const axios = createAxiosInstance();
    const showToasts = useToast();

    const fetchScenes = async () => {
        try {
            const response = await axios.get('/scene');
            setScenes(response.data);
        } catch (error) {
            console.error('Error fetching scenes:', error);
        }
    };

    useEffect(() => {
        fetchScenes();
    }, []);

    const handleSubmit = async () => {
        const newScene: Scene = {name, cronTime, action, mqttTopic, isActive};
        try {
            await axios.post('/scene', newScene)
                .then(() => {
                    setName('');
                    setCronTime('* * * * *');
                    setAction('');
                    setMqttTopic('');
                    setIsActive(true);
                    showToasts('Scene succesvol opgeslagen!', 'success');
                    fetchScenes();
                })
                .catch((error) => {
                    console.error('Error saving scene:', error);
                    showToasts('Er is iets misgegaan met het opslaan van de scene', 'danger');
                });
        } catch (error) {
            console.error('Error saving scene:', error);
        }
    };

    const handleDelete = async (id: string | undefined) => {
        try {
            await axios.delete(`/scene/${id}`);
            showToasts('Scene succesvol verwijderd!', 'success');
            fetchScenes();
        } catch (error) {
            console.error('Error deleting scene:', error);
            showToasts('Er is iets misgegaan met het verwijderen van de scene', 'danger');
        }
    };

    async function updateSceneStatus(scene: Scene, isActive: boolean) {
        try {
            const updatedScene = {...scene, isActive};
            await axios.put(`/scene/${scene._id}`, updatedScene);
            showToasts('Scene status bijgewerkt!', 'success');
            await fetchScenes();
        } catch (error: unknown) {
            const axiosError = error as AxiosError<any>;
            console.error('Error updating scene status:', axiosError);

            const errorMessage = axiosError.response?.data?.message || 'Er is iets misgegaan met het bijwerken van de scene status';
            showToasts(errorMessage, 'danger');
        }
    }

    function toggleSceneActive(scene: Scene) {
        return async (e: CustomEvent<{ checked: boolean }>) => {
            if (!scene._id) {
                showToasts('Scene ID ontbreekt. Kan de scene status niet bijwerken.', 'danger');
                return;
            }
            await updateSceneStatus(scene, e.detail.checked);
        };
    }


    return (
        <IonCard className="scene-card">
            <IonCardHeader className="scene-card-header">
                <IonTitle className="scene-title">Bouw een scene</IonTitle>
            </IonCardHeader>
            <IonCardContent className="scene-card-content">
                <IonItem className="scene-item">
                    <IonLabel position="stacked" className="scene-label">Naam</IonLabel>
                    <IonInput className="scene-input" value={name} onIonChange={(e) => setName(e.detail.value!)}/>
                </IonItem>
                <IonItem className="scene-item">
                    <IonLabel position="stacked" className="scene-label">Cron Tijd</IonLabel>
                    <IonInput className="scene-input" value={cronTime} disabled/>
                    <IonButton expand={'full'} className={'cron-set-button'} onClick={() => setShowPopover(true)}>Cron
                        tijd maken</IonButton>
                    <IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
                        <CronTimeBuilderComponent onCronChange={(cronExpression) => {
                            setCronTime(cronExpression);
                            setShowPopover(false);
                        }}/>
                    </IonPopover>
                </IonItem>
                <IonItem className="scene-item">
                    <IonLabel position="stacked" className="scene-label">Actie</IonLabel>
                    <IonInput className="scene-input" value={action} onIonChange={(e) => setAction(e.detail.value!)}/>
                </IonItem>
                <IonItem className="scene-item">
                    <IonLabel position="stacked" className="scene-label">MQTT Topic</IonLabel>
                    <IonInput className="scene-input" value={mqttTopic}
                              onIonChange={(e) => setMqttTopic(e.detail.value!)}/>
                </IonItem>
                <IonItem className="scene-item">
                    <IonToggle enableOnOffLabels={true} className="scene-toggle" checked={isActive}
                               onIonChange={(e) => setIsActive(e.detail.checked)}>Actief</IonToggle>
                </IonItem>
                <IonButton className="scene-button" onClick={handleSubmit}>Scene opslaan</IonButton>
            </IonCardContent>
            <IonCardContent>
                <IonList>
                    <IonListHeader>
                        <IonLabel>Scene's</IonLabel>
                    </IonListHeader>
                    {scenes.map((scene, index) => (
                        <div key={index}>
                            <IonCardContent className={'existing-cron-job'}>
                                <div>
                                    <h2>{scene.name}</h2>
                                    <p>Tijd: {scene.cronTime}</p>
                                    <p>Actie: {scene.action}</p>
                                    <p>Topic: {scene.mqttTopic}</p>
                                    <p>{scene.isActive ? 'Actief' : 'Inactief'}</p>
                                    <IonButton color="danger"
                                               onClick={() => handleDelete(scene._id)}>Verwijderen</IonButton>
                                </div>
                                <div>
                                    <IonToggle
                                        checked={scene.isActive}
                                        onIonChange={toggleSceneActive(scene)}
                                    />
                                </div>
                            </IonCardContent>
                        </div>
                    ))}
                </IonList>
            </IonCardContent>
        </IonCard>
    );
};

export default MqttSceneBuilderComponent;
