import React from "react";
import {IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar} from "@ionic/react";
import {Link} from "react-router-dom";

const ResetPasswordEmailSend: React.FC = () => {
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Email verzonden</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <div style={{
                    padding: '20px',
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center'
                }}>
                    <h1>Email verzonden</h1>
                    <p style={{
                        color: '#555',
                        fontSize: '18px',
                    }}>
                        <p>
                            Als je email overeenkomt met een account, dan is er een email verzonden met instructies om
                            je wachtwoord te resetten.
                        </p>
                        <Link to="/login">Klik hier om terug te gaan naar de login pagina</Link>
                    </p>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordEmailSend;
