import {IonCard, IonCardContent, IonCardHeader, IonIcon, IonRange, IonTitle} from '@ionic/react';
import {useEffect, useState} from "react";
import {sunny} from "ionicons/icons";

interface SliderComponentProps {
    title: string;
    onValueChange: (value: number) => void;
    initialValue: number;
}

const MqttBrightnessComponent: React.FC<SliderComponentProps> = ({title, onValueChange, initialValue}) => {

    const [sliderValue, setSliderValue] = useState<number>(initialValue);

    const handleSliderChange = (e: CustomEvent) => {
        const newValue = e.detail.value as number;
        setSliderValue(newValue);
        onValueChange(newValue);
    };

    useEffect(() => {
        setSliderValue(initialValue);
    }, [initialValue]);

    return (
        <IonCard>
            <IonCardHeader>
                <IonTitle>
                    {title} {sliderValue}
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRange value={sliderValue} min={0} max={100} step={1} snaps={true} pin={true}
                          onIonChange={handleSliderChange}>
                    <IonIcon size="small" slot="start" icon={sunny}/>
                    <IonIcon slot="end" icon={sunny}/>
                </IonRange>
            </IonCardContent>
        </IonCard>
    );
};

export default MqttBrightnessComponent;
