import axios from "axios";

export class Auth {

    isJWTValid = () => {
        let token: string | null = ''
        try {
            token = localStorage.getItem('jwt')
            if (token != null) {
                // Split the token into header, payload, and signature
                const [header, payload, signature] = token.split('.');

                // Decode the header and payload
                const decodedHeader = JSON.parse(atob(header));
                const decodedPayload = JSON.parse(atob(payload));

                // Check if the token is expired
                if (decodedPayload.exp && decodedPayload.exp < Date.now() / 1000) {
                    return false;
                }
                return true;
            }
            return false;
            // The token is valid
        } catch (error) {
            // The token is invalid
            return false;
        }
    }

    isUserAuthorized = async () => {
        try {
            const accessToken = localStorage.getItem('jwt');
            if (!accessToken) {
                return false;
            }

            const response = await axios.get('/auth/auth', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response.status === 200;

        } catch (error) {
            console.error(error);
            return false;
        }
    }

    register(username: string, password: string) {
        const registerBody = {
            username: username,
            password: password
        }
        axios.post(`https://thehudd.eu/api/auth/register`, registerBody)
            .then(res => {
                localStorage.setItem('jwt', res.data.accessToken);
            })
            .catch(err => console.error(err));
    }

    logout() {
        // Remove the JWT from local storage
        localStorage.removeItem('jwt');
    }
}

