import {IonButton, IonCard, IonCardContent, IonCardHeader, IonIcon, IonRange, IonTitle} from '@ionic/react';
import './Page.css';
import {useEffect, useState} from "react";
import {beer} from "ionicons/icons";
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";
import "./Controller.css"

const Controller: React.FC = () => {

    const [fridgeSetting, setFridgeSetting] = useState<number>(50);
    const axios = createAxiosInstance();
    const showToasts = useToast();


    useEffect(() => {
        const fetchCurrentFridgeSetting = () => {
            axios.get("/mqtt/fridge-status")
                .then(res => {
                    setFridgeSetting(res.data.fridgeStatus)
                });
        }
        fetchCurrentFridgeSetting()
    }, []);

    const sendFridgeSetting = () => {
        const body = {
            redIndicator: fridgeSetting
        };
        axios.post("/mqtt/send-mqtt", body)
            .then(res => {
                if (res.status === 200) {
                    showToasts(`Koelkast succesvol op kans 1 op ${fridgeSetting} gezet!`, 'success')
                } else {
                    showToasts("Er is iets mis gegaan, probeer het opnieuw", 'danger')
                }
            });
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonTitle>
                    Koelkast kans 1 op {fridgeSetting}
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonRange value={fridgeSetting} min={1} max={50} step={1} snaps={true} pin={true}
                          onIonInput={(e) => setFridgeSetting(e.detail.value as number)}>
                    <IonIcon size="small" slot="start" icon={beer}/>
                    <IonIcon slot="end" icon={beer}/>
                </IonRange>
                <div className={'button-box'}>
                    <IonButton className={'apply-setting-button'} onClick={sendFridgeSetting}>Instellen</IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default Controller;
