import React, {useEffect, useRef} from 'react';
import QRCode from 'qrcodejs2';

interface QRCodeComponentProps {
    value: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value }) => {
    const qrRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (qrRef.current) {
            qrRef.current.innerHTML = '';
            new QRCode(qrRef.current, {
                text: value,
                width: 128,
                height: 128,
            });
        }
    }, [value]);


    return <div ref={qrRef}></div>;
};

export default QRCodeComponent;
