import axios, {AxiosError, AxiosInstance} from 'axios';

const createAxiosInstance = (): AxiosInstance => {
    const baseURL: string = process.env.REACT_APP_API || 'https://thehudd.eu/api';
    const jwtToken = localStorage.getItem('jwt');
    return axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    });
};

export const isAxiosError = (error: any): error is AxiosError => {
    return error.isAxiosError;
}

export default createAxiosInstance;
