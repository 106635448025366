import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    useIonAlert
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {arrowForward, chevronDownCircleOutline, logoEuro, sad} from "ionicons/icons";
import createAxiosInstance from "../services/AxiosInstance";
import BeerDiscounts from "../components/BeerDiscounts";
import './PersonalStats.css'
import {BeerDiscount} from "../model/BeerDiscount";
import {User} from "../model/User";
import Dashboard from "../components/Dashboard";
import {useToast} from "../Utils/useToasts";
import {Link, useLocation} from "react-router-dom";
import PercentageChart from "../components/PercentageChart";
import {DertigenStatistic} from "../model/DertigenStatistic";
import LoadingSpinner from "../components/LoadingSpinner";
import ProfilePicture from "../components/ProfilePicture";
import ErrorMessage from "../components/ErrorMessage";
import AddTransactionPopover from "../components/AddTransactionPopOver";

const PersonalStats: React.FC = () => {

    const location = useLocation()
    const [person, setPerson] = useState<User>();
    const [dertigenStatistic, setDertigenStatistic] = useState<DertigenStatistic>();
    const [amountOfEvenings, setAmountOfEvenings] = useState<number>();
    const [beerDiscounts, setBeerDiscounts] = useState<BeerDiscount[]>([]);
    const [selectedPage, setSelectedPage] = useState<string>("personal-stats");
    const showToasts = useToast();
    const axios = createAxiosInstance();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingDerigen, setIsLoadingDertigen] = useState<boolean>(true);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [scrapeDate, setScrapeDate] = useState<string>('');
    const [presentAlert] = useIonAlert();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);


    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setIsLoading(true)
        setIsLoadingDertigen(true)
        fetchPeronalData()
        fetchEveningsCount()
        fetchDertigenStatisticsData()
        event.detail.complete();
    }

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePicFile(file);
            // Directly call the upload function
            uploadProfilePic(file);
        }
    };

    const uploadProfilePic = (file: any) => {
        const formData = new FormData();
        formData.append('avatar', file || profilePicFile);

        axios.post(`/player/profile-picture/${person?.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                // Handle response, set the URL for the uploaded image
                setProfilePicUrl(response.data.filePath); // Assuming the server returns the file path
                showToasts('Profiel foto succesvol geupload', 'success');
            })
            .catch(error => {
                console.error('Error uploading profile picture:', error);
                showToasts('Failed to upload profile picture.', 'danger');
            });
    };


    function fetchPeronalData() {
        axios.get('/player/personal-data/header')
            .then(res => {
                setPerson(res.data)
                if (res.data.profilePictureUrl) {
                    setProfilePicUrl(res.data.profilePictureUrl);
                }
                console.log(res.data.emailAddress)
                setIsLoading(false);
            }).catch((error) => {
            console.error('Error fetching person data:', error);
            setIsLoading(false);
        });
    }

    function fetchDertigenStatisticsData() {
        axios.get('/dertigen/statistics')
            .then(res => {
                setDertigenStatistic(res.data)
                console.log(res.data)
                setIsLoadingDertigen(false);
            }).catch((error) => {
            console.error('Error fetching person data:', error);
            setIsLoadingDertigen(false);
        });
    }

    function fetchEveningsCount() {
        axios.get('/evening/')
            .then(res => {
                setAmountOfEvenings(res.data.count)
            }).catch((error) => {
            console.log(error)
        });
    }

    function fetchBeerDiscount() {
        axios.get('/discounts')
            .then(res => {
                console.log(res.data)
                setBeerDiscounts(res.data.discounts)
                setScrapeDate(res.data.scrapedDate)
            })
    }

    const addPuke = () => {
        axios.post('/puke')
            .then(() => {
                showToasts("Je hebt gekotst!", 'success')
                fetchPeronalData();
            })
            .catch(err => {
                console.error(err)
                showToasts('Er is iets mis gegaan', 'danger')
            })
    }

    const addMollieTransaction = (amount: number) => {
        axios.post('/transaction/mollie/create-transaction', {
            amount: amount,
        })
            .then(response => {
                if (response.status === 200) {
                    window.location.href = response.data.paymentUrl;
                } else {
                    showToasts('Er is iets mis gegaan', 'danger')
                }
            })
            .catch(err => {
                console.error(err)
                showToasts('We konden geen verbinding maken met Mollie, probeer het nogmaals', 'danger')
            })
    }

    const addTransaction = () => {
        setIsPopoverOpen(true);
    };

    const handlePopoverSubmit = (amount: number) => {
        addMollieTransaction(amount);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)

        const pageParam = searchParams.get("page");

        if (pageParam === "dashboard") {
            setSelectedPage("dashboard");
        }

        fetchPeronalData()
        fetchEveningsCount()
        fetchBeerDiscount()
        fetchDertigenStatisticsData()
    }, [location.search])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Welkom {person?.name}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen class={'personal-stats-content'}>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        refreshingSpinner="circles"
                        refreshingText="Verversen..."
                    />
                </IonRefresher>
                {person?.roles && person.roles.includes('user') && (
                    <>
                        <IonSegment
                            mode="ios"
                            defaultValue="personal-stats"
                            className="page-selector"
                            value={selectedPage}
                            onIonChange={(e) => e.detail.value && setSelectedPage(e.detail.value as string)}
                        >
                            <IonSegmentButton value="personal-stats">
                                <IonLabel>Mijn statistieken</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="dashboard">
                                <IonLabel>Dashboard</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </>
                )}
                {selectedPage === "personal-stats" && (
                    <>
                        <ErrorMessage
                            message={"Je account heeft nog geen geregistreerd e-mail adres, hierdoor kan je wachtwoord niet hersteld worden wanneer nodig. Ga alsjeblieft naar 'Mijn Account' en registreer een e-mail adres"}
                            isVisible={person !== undefined && person?.emailAddress === undefined}/>
                        <IonCard>
                            <IonCardHeader>
                                <IonTitle>Mijn profiel</IonTitle>
                            </IonCardHeader>
                            <IonCardContent className={'centered-profile-pic'}>
                                <IonItem>
                                    <ProfilePicture
                                        profilePicUrl={profilePicUrl.length !== 0 ? `${process.env.REACT_APP_STORAGE}${profilePicUrl}` : `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                                        onFileChange={handleFileChange}
                                        showEditable={true} width={100} height={100}/>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                        {(person?.roles?.includes('user') || person?.roles?.includes('regular-guest')) && (
                            <>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonTitle>Financieel</IonTitle>
                                    </IonCardHeader>
                                    {isLoading ? (
                                        <IonCardContent className="spinner-grid">
                                            <div className="spinner-container">
                                                <LoadingSpinner/>
                                            </div>
                                        </IonCardContent>
                                    ) : (
                                        <IonCardContent className={'custom-grid'}>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Saldo</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <p>€{person?.balance},-</p>
                                                </IonCardContent>
                                            </div>
                                            <div className="custom-card">
                                                <IonCardHeader
                                                    className="custom-card-header">Uitgegeven</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <p>€{person?.expense},-</p>
                                                </IonCardContent>
                                            </div>
                                            <div className="custom-card">
                                                <IonCardHeader
                                                    className="custom-card-header">Opwaarderen met
                                                    Mollie</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <IonButton className={'pay-for-balance-button'}
                                                               onClick={addTransaction}>
                                                        <IonIcon icon={logoEuro}></IonIcon>
                                                    </IonButton>
                                                </IonCardContent>
                                            </div>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonTitle>
                                            <div className={'presence-card-title'}>
                                                Aanwezigheid
                                                <Link style={{textDecoration: 'none'}}
                                                      to={`/presence/${person?.id}`}>
                                                    <IonButton className={'route-evenings-button'} shape={'round'}>
                                                        <IonIcon icon={arrowForward}></IonIcon>
                                                    </IonButton>
                                                </Link>
                                            </div>
                                        </IonTitle>
                                    </IonCardHeader>
                                    {isLoading ? (
                                        <IonCardContent className="spinner-grid">
                                            <div className="spinner-container">
                                                <LoadingSpinner/>
                                            </div>
                                        </IonCardContent>
                                    ) : (
                                        <IonCardContent class={'custom-grid'}>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Hoevaak
                                                    aanwezig</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <p>{person?.amountOfEvenings}</p>
                                                </IonCardContent>
                                            </div>
                                            {person && amountOfEvenings && (
                                                <div className="custom-card">
                                                    <IonCardHeader className="custom-card-header">% van alle
                                                        avonden</IonCardHeader>
                                                    <IonCardContent className="custom-percentage-card-content">
                                                        <p>{((person?.amountOfEvenings / amountOfEvenings) * 100).toFixed(2)}%</p>
                                                        <div className={'percentage-component'}>
                                                            <PercentageChart
                                                                percentage={(person?.amountOfEvenings / amountOfEvenings) * 100}></PercentageChart>
                                                        </div>
                                                    </IonCardContent>
                                                </div>
                                            )}
                                        </IonCardContent>
                                    )}
                                </IonCard>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonTitle>Barry</IonTitle>
                                    </IonCardHeader>
                                    {isLoading ? (
                                        <IonCardContent className="spinner-grid">
                                            <div className="spinner-container">
                                                <LoadingSpinner/>
                                            </div>
                                        </IonCardContent>
                                    ) : (
                                        <IonCardContent class={'custom-grid'}>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Hoevaak
                                                    genekt</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <p>{person?.amountOfPukes}</p>
                                                </IonCardContent>
                                            </div>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Ik heb
                                                    gekotst</IonCardHeader>
                                                <IonCardContent className="custom-card-content">
                                                    <IonButton onClick={addPuke}>
                                                        Genekt
                                                        <IonIcon icon={sad}></IonIcon>
                                                    </IonButton>
                                                </IonCardContent>
                                            </div>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </>
                        )}
                        <IonCard>
                            <IonCardHeader>
                                <IonTitle>Doelstellingen</IonTitle>
                            </IonCardHeader>
                            {isLoadingDerigen ? (
                                <IonCardContent className="spinner-grid">
                                    <div className="spinner-container">
                                        <LoadingSpinner/>
                                    </div>
                                </IonCardContent>
                            ) : (
                                <IonCardContent className="custom-grid">
                                    {dertigenStatistic && (
                                        <>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Wekelijks strependoel: 400 strepen</IonCardHeader>
                                                <IonCardContent className="custom-percentage-card-content">
                                                    {dertigenStatistic.gulpsThisWeek >= 400 ? (
                                                        <>
                                                            <p>Doel van 400 strepen bereikt!</p>
                                                            <div className={'percentage-component'}>
                                                                <PercentageChart percentage={100}></PercentageChart>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>{((dertigenStatistic.gulpsThisWeek / 400) * 100).toFixed(1)}%</p>
                                                            <p>{dertigenStatistic.gulpsThisWeek} van de 400
                                                                gedronken</p>
                                                            <div className={'percentage-component'}>
                                                                <PercentageChart
                                                                    percentage={(dertigenStatistic.gulpsThisWeek / 400) * 100}></PercentageChart>
                                                            </div>
                                                        </>
                                                    )}
                                                </IonCardContent>
                                            </div>
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Jaarlijks strependoel: 35.000
                                                    strepen</IonCardHeader>
                                                <IonCardContent className="custom-percentage-card-content">
                                                    {dertigenStatistic.gulpsThisYear >= 35000 ? (
                                                        <>
                                                            <p>Jaarlijks strependoel bereikt!</p>
                                                            <div className={'percentage-component'}>
                                                                <PercentageChart percentage={100}></PercentageChart>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>{((dertigenStatistic.gulpsThisYear / 35000) * 100).toFixed(1)}%</p>
                                                            <p>{dertigenStatistic.gulpsThisYear} van de 35.000
                                                                gedronken</p>
                                                            <div className={'percentage-component'}>
                                                                <PercentageChart
                                                                    percentage={(dertigenStatistic.gulpsThisYear / 35000) * 100}></PercentageChart>
                                                            </div>
                                                        </>
                                                    )}
                                                </IonCardContent>
                                            </div>
                                        </>
                                    )}
                                </IonCardContent>
                            )}
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonTitle>Dertigen</IonTitle>
                            </IonCardHeader>
                            {isLoadingDerigen ? (
                                <IonCardContent className="spinner-grid">
                                    <div className="spinner-container">
                                        <LoadingSpinner/>
                                    </div>
                                </IonCardContent>
                            ) : (
                                <IonCardContent className="custom-grid">
                                    <>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Jouw
                                                record</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.dertigenRecord}</p>
                                            </IonCardContent>
                                        </div>

                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Laatste
                                                score</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.dertigenRecent}</p>
                                            </IonCardContent>
                                        </div>

                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Strepen per
                                                beurt</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.meanThrows}</p>
                                            </IonCardContent>
                                        </div>

                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Gedronken
                                                strepen</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.gulpsPerPlayer}</p>
                                            </IonCardContent>
                                        </div>

                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Hoeveel
                                                glazen</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.glassesPerPlayer}</p>
                                            </IonCardContent>
                                        </div>

                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Totaal
                                                gezopen</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.totalGulps} strepen</p>
                                            </IonCardContent>
                                        </div>
                                        {dertigenStatistic && (
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Percentage van
                                                    totaal</IonCardHeader>
                                                <IonCardContent className="custom-percentage-card-content">
                                                    <p>{((dertigenStatistic?.gulpsPerPlayer / dertigenStatistic?.totalGulps) * 100).toFixed(2)}%</p>
                                                    <div className={'percentage-component'}>
                                                        <PercentageChart
                                                            percentage={(dertigenStatistic?.gulpsPerPlayer / dertigenStatistic?.totalGulps) * 100}></PercentageChart>
                                                    </div>
                                                </IonCardContent>
                                            </div>
                                        )}
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Hoevaak
                                                streper</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.amountOfTimesStiper}</p>
                                            </IonCardContent>
                                        </div>
                                        {dertigenStatistic && (
                                            <div className="custom-card">
                                                <IonCardHeader className="custom-card-header">Streper per
                                                    lobby</IonCardHeader>
                                                <IonCardContent className="custom-percentage-card-content">
                                                    <p>{((dertigenStatistic?.amountOfTimesStiper / dertigenStatistic?.amountOfLobbies) * 100).toFixed(2)}%</p>
                                                    <div className={'percentage-component'}>
                                                        <PercentageChart
                                                            percentage={(dertigenStatistic?.amountOfTimesStiper / dertigenStatistic?.amountOfLobbies) * 100}></PercentageChart>
                                                    </div>
                                                </IonCardContent>
                                            </div>
                                        )}
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Verdubbelaars</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.amountOfDoublers}</p>
                                            </IonCardContent>
                                        </div>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Straatjes
                                                gegooid</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.amountOfStreetsThrown}</p>
                                            </IonCardContent>
                                        </div>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Meeste straatjes van gekregen</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.mostStreetsReceivedFrom.player} - {dertigenStatistic?.mostStreetsReceivedFrom.amount}</p>
                                            </IonCardContent>
                                        </div>
                                        <div className="custom-card">
                                            <IonCardHeader className="custom-card-header">Meeste straatjes gegeven</IonCardHeader>
                                            <IonCardContent className="custom-card-content">
                                                <p>{dertigenStatistic?.mostStreetsGivenTo?.player} - {dertigenStatistic?.mostStreetsGivenTo?.amount}</p>
                                            </IonCardContent>
                                        </div>
                                    </>
                                </IonCardContent>
                            )}
                        </IonCard>
                        {beerDiscounts && (
                            <IonCard>
                                <IonCardHeader>
                                    <IonTitle>
                                        Bier kortingen
                                    </IonTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonLabel class={'refresh-label'}>Ververst op {scrapeDate}</IonLabel>
                                    {beerDiscounts && <BeerDiscounts discounts={beerDiscounts}/>}
                                </IonCardContent>
                            </IonCard>
                        )}
                        <IonCard>
                            <IonCardHeader>
                                <IonTitle>
                                    Contact
                                </IonTitle>
                            </IonCardHeader>
                            <IonCardContent className={'custom-grid'}>
                                <div className="custom-card">
                                    <IonCardHeader className="custom-card-header">Telefoon</IonCardHeader>
                                    <IonCardContent className="custom-card-content">
                                        <p>06-28294256</p>
                                    </IonCardContent>
                                </div>
                                <div className="custom-card">
                                    <IonCardHeader className="custom-card-header">Email</IonCardHeader>
                                    <IonCardContent className="custom-card-content">
                                        <p>hethuddje@gmail.com</p>
                                    </IonCardContent>
                                </div>
                            </IonCardContent>
                        </IonCard>
                        <AddTransactionPopover
                            isOpen={isPopoverOpen}
                            onClose={() => setIsPopoverOpen(false)}
                            onSubmit={handlePopoverSubmit}
                        />
                    </>
                )}
                {selectedPage === 'dashboard' && <Dashboard/>}
            </IonContent>
        </IonPage>
    );
};

export default PersonalStats;
