import {IonButton, IonCard, IonCardHeader, IonCol, IonInput, IonItem, IonRow} from "@ionic/react";
import {useEffect, useState} from "react";
import './DertigenMoveInput.css';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";


interface DertigenMoveInputProps {
    currentPlayer: any;
    lobbyId: any;
    throwForPlayerId: any;
}

const DertigenMoveInput: React.FC<DertigenMoveInputProps> = ({currentPlayer, lobbyId, throwForPlayerId}) => {
    const [player, setPlayer] = useState<any>({})
    const [amountOfStripesForNextPlayer, setAmountOfStripesForNextPlayer] = useState<string>('');
    const [amountOfStripesForSelf, setAmountOfStripesForSelf] = useState<string>('');
    const [throwForPlayer, setThrowForPlayer] = useState<string>('');
    const axios = createAxiosInstance();
    const showToasts = useToast();

    const addStripesToSelf = (amountOfStripes: number) => {
        axios.patch(`/dertigen/move/${lobbyId}/${player.playerId}`,
            {
                deltaStripes: Number(amountOfStripes),
                deltaGulps: 0,
                isTurnMove: true,
                thrownBy: player.playerId
            })
            .then(res => {
                setAmountOfStripesForNextPlayer('')
                setPlayer(res.data)
            }).catch(err => {
            if (err.response.status === 405) {
                showToasts('Je hebt geen toestemming', "danger")
            } else {
                showToasts('Er is iets mis gegaan', "danger")
            }
        })
    }

    const doubler = () => {
        axios.patch(`/dertigen/double/${lobbyId}/${player.playerId}`)
            .then(() => {
                showToasts("Je hebt de anderen verdubbeld!", "success")
            }).catch(err => {
            if (err.response.status === 405) {
                showToasts('Je hebt geen toestemming', "danger")
            } else {
                showToasts('Er is iets mis gegaan', "danger")
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && (amountOfStripesForNextPlayer !== '' || amountOfStripesForSelf !== '')) {
            event.preventDefault()
            event.stopPropagation()

            if (amountOfStripesForNextPlayer !== '' && amountOfStripesForSelf !== '') {
                showToasts('Je kan niet voor jezelf gooien en voor de volgende speler', "danger")
                return
            } else if (amountOfStripesForNextPlayer !== '') {
                axios.patch(`/dertigen/move/${lobbyId}/${throwForPlayer}`,
                    {
                        deltaStripes: Number(amountOfStripesForNextPlayer),
                        deltaGulps: 0,
                        isTurnMove: true,
                        thrownBy: player.playerId
                    })
                    .then(res => {
                        setAmountOfStripesForNextPlayer('')
                        setPlayer(res.data)
                        showToasts('Strepen toegevoegd!', "success")
                        event.target.blur()
                    }).catch(err => {
                    if (err.response.status === 405) {
                        showToasts('Je hebt geen toestemming', "danger")
                    } else {
                        showToasts('Er is iets mis gegaan', "danger")
                    }
                })
            } else if (amountOfStripesForSelf !== '') {
                axios.patch(`/dertigen/move/${lobbyId}/${player.playerId}`,
                    {
                        deltaStripes: Number(amountOfStripesForSelf),
                        deltaGulps: 0,
                        isTurnMove: true,
                        thrownBy: player.playerId
                    })
                    .then(res => {
                        setAmountOfStripesForSelf('')
                        setPlayer(res.data)
                        showToasts('Strepen toegevoegd!', "success")
                        event.target.blur()
                    }).catch(err => {
                    if (err.response.status === 405) {
                        showToasts('Je hebt geen toestemming', "danger")
                    } else {
                        showToasts('Er is iets mis gegaan', "danger")
                    }
                })
            } else {
                showToasts('Je moet een aantal strepen invullen', "danger")
            }
        }
    }

    const addStreet = () => {
        axios.patch(`/dertigen/move/${lobbyId}/${throwForPlayer}`,
            {
                deltaStripes: 50,
                deltaGulps: 0,
                isTurnMove: true,
                thrownBy: player.playerId
            })
            .then(res => {
                setAmountOfStripesForNextPlayer('')
                setAmountOfStripesForSelf('')
                setPlayer(res.data)
                showToasts('Straat gegooid!', "success")
            }).catch(err => {
            if (err.response.status === 405) {
                showToasts('Je hebt geen toestemming', "danger")
            } else {
                showToasts('Er is iets mis gegaan', "danger")
            }
        })
    }


    useEffect(() => {
        setPlayer(currentPlayer);
        setThrowForPlayer(throwForPlayerId)
    }, [currentPlayer, throwForPlayerId]);

    return (
        <>
            <IonCard>
                <IonCardHeader>{player?.player} is aan de beurt</IonCardHeader>
            </IonCard>
            <IonRow class='stripes-button-box'>
                <IonButton className='stripes-to-self' onClick={() => addStripesToSelf(5)}>+5 </IonButton>
                <IonButton className='stripes-to-self' onClick={() => addStripesToSelf(10)}>+10</IonButton>
                <IonButton className='stripes-to-self' onClick={() => addStripesToSelf(15)}>+15</IonButton>
                <IonButton className='stripes-to-self' onClick={() => addStripesToSelf(20)}>+20</IonButton>
            </IonRow>
            <IonRow class='special-button-box'>
                <IonButton className='others' onClick={() => doubler()}>X 2</IonButton>
                <IonButton className='others' onClick={() => addStreet()}>Straatje</IonButton>
            </IonRow>
            <div className={'input-container'}>
                <IonCol>
                    <IonItem className="input-for-self">
                        <IonInput
                            type="number"
                            value={amountOfStripesForSelf}
                            placeholder="Voor gooier"
                            onIonInput={e => setAmountOfStripesForSelf(e.detail.value!)}
                            onKeyDown={handleKeyDown}
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem className="input-for-next">
                        <IonInput
                            type="number"
                            value={amountOfStripesForNextPlayer}
                            placeholder="Volgende speler"
                            onIonInput={e => setAmountOfStripesForNextPlayer(e.detail.value!)}
                            onKeyDown={handleKeyDown}
                        />
                    </IonItem>
                </IonCol>
            </div>
        </>
    )
        ;
};

export default DertigenMoveInput;
