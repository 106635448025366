import React from 'react';
import {IonIcon} from '@ionic/react';
import {warningOutline} from 'ionicons/icons';

interface ErrorMessageProps {
    message: string;
    isVisible: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, isVisible }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '20px', padding: '10px', backgroundColor: 'rgba(255,137,4,0.1)', border: '1px solid orange', borderRadius: '5px' }}>
            <IonIcon icon={warningOutline} style={{ flexShrink: 0, width: '30px', height: '30px', marginRight: '10px' }} />
            <span>{message}</span>
        </div>
    );
};

export default ErrorMessage;
