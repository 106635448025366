import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import createAxiosInstance, {isAxiosError} from "../services/AxiosInstance";

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    path?: string;
    exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, ...rest}: any) => {

    const axios = createAxiosInstance();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [authStatus, setAuthStatus] = useState<number>(0); // Adding a state to keep track of the authorization status

    useEffect(() => {
        const isUserAuthorized = async () => {
            try {
                const accessToken = localStorage.getItem('jwt');

                if (!accessToken) {
                    setIsAuthenticated(false);
                    return;
                }

                const response = await axios.get('/auth/auth', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);
                }

            } catch (error) {
                console.error(error);
                setIsAuthenticated(false);

                // Check if error is an instance of AxiosError
                if (isAxiosError(error)) {
                    // Now TypeScript knows this is an Axios error
                    const axiosError = error; // error is now of type AxiosError
                    if (axiosError.response) {
                        // Handle error response from Axios
                        setAuthStatus(axiosError.response.status);
                    } else {
                        // Handle Axios errors that do not have a response (like network errors)
                    }
                } else {
                    // Handle non-Axios errors
                    console.error("An error occurred:", error);
                }
            }
        }
        isUserAuthorized();
    }, []);

    if (isAuthenticated === null) return null;

    // Handle different response statuses
    const renderComponent = (props: any) => {
        if (isAuthenticated) {
            return <Component {...props} />;
        } else {
            return <Redirect to="/login"/>;
        }
    };

    return (
        <Route {...rest} render={renderComponent}/>
    );
};

export default PrivateRoute;
