import React, {useCallback, useState} from 'react';
import {IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {useHistory} from 'react-router-dom';
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";
import EmailInput from "../components/EmailInput";

const RequestPasswordResetPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [emailValid, setEmailValid] = useState<boolean>(true);
    const axios = createAxiosInstance();
    const showToasts = useToast();
    const history = useHistory();

    const handleEmailChange = useCallback((newEmail: string, isValid: boolean) => {
        setEmail(newEmail);
        setEmailValid(isValid);
    }, []);

    const handleResetPassword = async () => {
        if (!emailValid) {
            showToasts('Vul een geldig e-mailadres in.', 'danger');
            return;
        }

        await axios.post('/auth/forgot-password', {
            email: email,
        }).then(res => {
            showToasts('Email verzonden.', 'success');
            history.push('/reset-password-email-send');
        }).catch(err => {
            showToasts('Geen account gevonden voor dit e-mail addres', 'danger');
        });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Wachtwoord herstellen</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div style={{maxWidth: '500px', margin: 'auto'}}>
                    <h2>Herstel je wachtwoord</h2>
                    <p>
                        Wachtwoord verloren? Vul uw e-mailadres in. U ontvangt een link om een nieuw wachtwoord aan te
                        maken.
                    </p>
                    <EmailInput onEmailChange={handleEmailChange}/>
                    <IonButton expand="block" onClick={handleResetPassword} style={{marginTop: '20px'}}>
                        Herstel wachtwoord
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default RequestPasswordResetPage;
