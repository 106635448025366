import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    RefresherEventDetail
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import './DertigenList.css';
import {useHistory} from 'react-router-dom'
import './DertigenLobbyList.css'
import {chevronDownCircleOutline} from "ionicons/icons";
import createAxiosInstance from "../services/AxiosInstance";
import {User} from "../model/User";
import {Lobby} from "../model/Lobby";
import {useToast} from "../Utils/useToasts";
import LoadingSpinner from "../components/LoadingSpinner";

const DertigenList: React.FC = () => {

    const [lobbies, setLobbies] = useState<Lobby[]>([]);
    const [newLobbyName, setNewLobbyName] = useState<string>('')
    const [isClassic, setIsClassic] = useState<boolean>(false);
    const [user, setUser] = useState<User>();
    const showToasts = useToast();
    const axios = createAxiosInstance();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const history = useHistory();

    const keyPress = (event: { key: string; }) => {
        if (event.key === 'Enter' && newLobbyName !== '') {
            setIsLoading(true)
            const newLobby = {
                name: newLobbyName,
                owner: user?.id,
                isMachineControlledLobby: !isClassic
            }
            setNewLobbyName('')

            axios.post('/dertigen', newLobby)
                .then(res => {
                    showToasts(`Lobby: ${res.data.name} is toegevoegd!`, 'success')
                    setLobbies((prevLobbies: any) => {
                        return [...prevLobbies, res.data]
                    })
                    setIsLoading(false)
                })
                .catch(reason => {
                    showToasts('Er is iets misgegaan, probeer het opnieuw!', 'danger')
                    setIsLoading(false)
                })
        }
    }

    const joinLobby = (lobbyId: string) => {
        setIsLoading(true)
        axios.patch(`/dertigen/${lobbyId}/join/${user?.id}`)
            .then(() => {
                showToasts("Je bent de lobby gejoined", "success")
                history.push(`/dertigen/${lobbyId}`)
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status === 405) {
                    showToasts('Je bent al gejoined', 'danger')
                } else {
                    showToasts('Er is iets mis gegaan, probeer het alsjeblieft opnieuw', 'danger')
                }
                setIsLoading(false)
            })
    }

    const fetchPeronalData = () => {
        axios.get('/player/personal-data/header')
            .then(res => {
                setUser(res.data)
            })
            .catch(error => {
                console.error(error);
            })
    }

    const fetchLobbies = () => {
        axios.get('/dertigen')
            .then((response) => {
                setLobbies(response.data)
            });
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        fetchLobbies()
        event.detail.complete();
    }

    useEffect(() => {
        fetchLobbies()
        fetchPeronalData()
    }, [])

    function navigateToGame(lobbyId: string) {
        history.push(`/dertigen/${lobbyId}`)
    }

    return (
        <IonPage className="lobby-page">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Kies een lobby</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        refreshingSpinner="circles"
                        refreshingText="Verversen...">
                    </IonRefresherContent>
                </IonRefresher>
                {isLoading ? (
                        <LoadingSpinner/>
                    ) :
                    (<>
                            <IonList>
                                {lobbies.map((value: any, key: any) => (
                                    <IonCard key={key} className='ion-text-center'>
                                        <IonItem>
                                            <IonButton onClick={() => joinLobby(value?._id)}
                                                       className="form-button">Joinen</IonButton>
                                            <IonButton className="form-button"
                                                       onClick={() => navigateToGame(value?._id)}>Kijken</IonButton>
                                            <IonLabel className="ion-text-center">
                                                {value?.name}
                                            </IonLabel>
                                            <IonNote slot='end'
                                                     color="success">{value?.players.length} spelers</IonNote>
                                        </IonItem>
                                    </IonCard>
                                ))}
                            </IonList>
                            <IonCard>
                                <IonCardHeader>
                                    Lobby aanmaken
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonItem>
                                        <IonLabel>Lobby naam</IonLabel>
                                        <IonInput value={newLobbyName} onKeyPress={keyPress}
                                                  onIonInput={(e: any) => setNewLobbyName(e.target.value!)}
                                                  placeholder="Naam"
                                                  clearInput type="text"/>
                                    </IonItem>
                                        <IonItem lines="none" className="custom-checkbox">
                                            <IonCheckbox slot="start" checked={isClassic} onIonChange={e => setIsClassic(e.detail.checked)} />
                                            <IonLabel className="custom-checkbox-label">Klassiek strepen</IonLabel>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </>
                    )}
            </IonContent>
        </IonPage>
    );
};

export default DertigenList;
