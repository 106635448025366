import {IonButton, IonContent, IonHeader, IonInput, IonLoading, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {useEffect, useState} from "react";
import {Link, useHistory} from 'react-router-dom'
import createAxiosInstance from '../services/AxiosInstance';

import {Auth} from '../Auth';
import {useToast} from "../Utils/useToasts";

const auth = new Auth();

const Login: React.FC = () => {
    const history = useHistory();
    const [busy, setBusy] = useState<boolean>(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const axios = createAxiosInstance();
    const showToasts = useToast();

    useEffect(() => {
        // Check the login status as soon as the component is rendered
        if (auth.isJWTValid()) {
            history.push('/');
        }
    }, []);

    async function login() {
        try {
            setBusy(true);
            await performLogin(username, password);
            setBusy(false);
        } catch (e) {
            console.error(e)
        }
    }

    const performLogin = async (username: string, password: string) => {
        // Replace this with a call to your backend to log the user in
        const loginBody = {
            username: username,
            password: password
        }
        axios.post(`/auth/login`, loginBody)
            .then(res => {
                if (res.status === 200) {
                    showToasts('Succesvol ingelogd!', 'success')
                    localStorage.setItem('jwt', res.data.accessToken);
                    history.push('/welkom')
                }
            })
            .catch(err => {
                console.error(err);
                showToasts('Inlog onsuccesvol', 'danger')
            });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonLoading message="Please wait.." duration={0} isOpen={busy}/>
            <IonContent className="ion-padding">
                <IonInput
                    placeholder="Gebruikersnaam"
                    onIonInput={(e: any) => setUsername(e.target.value)}
                />
                <IonInput
                    type="password"
                    placeholder="Wachtwoord"
                    onIonInput={(e: any) => setPassword(e.target.value)}
                />
                <IonButton onClick={login}>Login</IonButton>
                <p>
                    Nog geen account? <a href="/register">Registreer</a>
                </p>
                <p>
                    <Link to="/request-password-reset">Wachtwoord vergeten?</Link>
                </p>
            </IonContent>
        </IonPage>
    );
}

export default Login;
