import React from 'react';
import {IonAvatar, IonIcon} from '@ionic/react';
import {pencilOutline} from 'ionicons/icons';

interface ProfilePictureProps {
    profilePicUrl: string;
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showEditable: boolean
    width: number,
    height: number
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({profilePicUrl, onFileChange, showEditable, width, height}) => {
    return (
        <>
            <input
                type="file"
                accept="image/*"
                onChange={onFileChange}
                style={{ display: 'none' }}
                id="fileInput"
            />
            <IonAvatar style={{
                position: 'relative',
                width: `${width}px`,
                height: `${height}px`
            }}>
                <img
                    alt="Profile"
                    src={profilePicUrl || `${process.env.REACT_APP_STORAGE}default-profile.jpg`}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
                {showEditable && (
                    <IonIcon
                        icon={pencilOutline}
                        style={{
                            background: 'black',
                            color: 'white',
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            padding: '-0.5em',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                        onClick={() => document.getElementById('fileInput')?.click()}
                    />
                )}
            </IonAvatar>
        </>
    );
};

export default ProfilePicture;
