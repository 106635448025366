// src/pages/FirewallSettings.tsx

import React, {useEffect, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {FirewallRule} from "../model/FirewallRule";
import createAxiosInstance from "../services/AxiosInstance";
import {useToast} from "../Utils/useToasts";
import LoadingSpinner from "../components/LoadingSpinner";

const FirewallSettings: React.FC = () => {
    // Placeholder for firewall rules state
    const [firewallRules, setFirewallRules] = React.useState<FirewallRule[]>([]);
    const axios = createAxiosInstance();
    const [showAddDialog, setShowAddDialog] = useState(false);
    const showToasts = useToast();
    const [isLoading, setIsLoading] = useState(false);


    // Fetch firewall rules from the server (you would replace this with your actual API call)
    useEffect(() => {
        const fetchFirewallRules = async () => {
            setIsLoading(true);
            await axios.get('/database-ssh/list-ufw-rules')
                .then(
                    res => {
                        setFirewallRules(res.data);
                        setIsLoading(false);
                    }
                ).catch(err => {
                    showToasts('Er is iets mis gegaan', "danger")
                    console.error('Error fetching firewall rules:', err);
                    setIsLoading(false);
                })
        }

        fetchFirewallRules();
    }, []);

    const addRule = async (ipAddres: string, port: string) => {

        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

        if (!ipRegex.test(ipAddres)) {
            showToasts("Ongeldig IP-adres", "danger")
            return
        }

        if (isNaN(Number(port))) {
            showToasts('Poort moet een nummer zijn', "danger")
            return
        }

        try {
            await axios.post('/database-ssh/add-ufw-rule', {
                ipAddress: ipAddres,
                port: port
            }).then(res => {
                setFirewallRules([...firewallRules, {ipAddress: ipAddres, port: Number(port)}]);
            })

        } catch (error) {
            console.error('Error adding new firewall rule:', error);
            showToasts('Er is iets mis gegaan', "danger");
        }

        setShowAddDialog(false);
    }

    // Logic to remove a firewall rule
    const removeRule = async (ipAddress: string, port: number) => {
        await axios.delete('/database-ssh/remove-ufw-rule', {
            data: {
                ipAddress,
                port
            }
        }).then(res => {
            showToasts('Regel verwijderd!', "success")
            setFirewallRules(firewallRules.filter(rule => !(rule.ipAddress === ipAddress && rule.port === port)));
        }).catch(err => {
            if (err.response.status === 405) {
                showToasts('Het is niet de bedoeling om het IP van de huidige verbonden backend server te verwijderen', "danger")
            } else {
                showToasts('Er is iets mis gegaan', "danger")
            }
        });
    };


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>Firewall Regels</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {isLoading ?
                    <>
                        <LoadingSpinner/>
                    </>
                    :
                    <>
                        <IonList>
                            {firewallRules?.map((rule, index) => (
                                <IonItem key={index}>
                                    <IonLabel>
                                        IP: {rule.ipAddress} - Poort: {rule.port}
                                    </IonLabel>
                                    <IonButton color="danger" onClick={() => removeRule(rule.ipAddress, rule.port)}>
                                        Verwijder
                                    </IonButton>
                                </IonItem>
                            ))}
                        </IonList>
                        <IonButton expand="block" onClick={() => setShowAddDialog(true)}>
                            Nieuwe firewall regel toevoegen
                        </IonButton>

                        <IonAlert
                            isOpen={showAddDialog}
                            onDidDismiss={() => setShowAddDialog(false)}
                            header={'Nieuwe firewall regel toevoegen'}
                            inputs={[
                                {
                                    name: 'ipAddress',
                                    type: 'text',
                                    placeholder: 'IP Address'
                                },
                                {
                                    name: 'port',
                                    type: 'number',
                                    placeholder: 'Port'
                                }
                            ]}
                            buttons={[
                                {
                                    text: 'Annuleer',
                                    role: 'cancel',
                                    handler: () => {
                                        setShowAddDialog(false);
                                    }
                                },
                                {
                                    text: 'Toevoegen',
                                    handler: (data) => {
                                        if (data.ipAddress && data.port) {
                                            addRule(data.ipAddress, data.port);
                                        }
                                    }
                                }
                            ]}
                        />
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default FirewallSettings;
