import React, { useState } from 'react';
import { IonButton, IonCard, IonCardContent, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

interface CronTimeBuilderComponentProps {
    onCronChange: (cronExpression: string) => void;
}

const CronTimeBuilderComponent: React.FC<CronTimeBuilderComponentProps> = ({ onCronChange }) => {
    const [minute, setMinute] = useState<string>('*');
    const [hour, setHour] = useState<string>('*');
    const [dayOfMonth, setDayOfMonth] = useState<string>('*');
    const [month, setMonth] = useState<string>('*');
    const [dayOfWeek, setDayOfWeek] = useState<string>('*');

    const handleCronChange = () => {
        const cronExpression = `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
        onCronChange(cronExpression);
    };

    return (
        <IonCard>
            <IonCardContent>
                <IonItem>
                    <IonLabel>Minuut</IonLabel>
                    <IonSelect value={minute} placeholder="Selecteer minuut" onIonChange={e => setMinute(e.detail.value!)}>
                        <IonSelectOption value="*">*</IonSelectOption>
                        {Array.from({ length: 60 }, (_, i) => (
                            <IonSelectOption key={i} value={i.toString()}>{i}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Uur</IonLabel>
                    <IonSelect value={hour} placeholder="Selecteer uur" onIonChange={e => setHour(e.detail.value!)}>
                        <IonSelectOption value="*">*</IonSelectOption>
                        {Array.from({ length: 24 }, (_, i) => (
                            <IonSelectOption key={i} value={i.toString()}>{i}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Dag van Maand</IonLabel>
                    <IonSelect value={dayOfMonth} placeholder="Selecteer dag" onIonChange={e => setDayOfMonth(e.detail.value!)}>
                        <IonSelectOption value="*">*</IonSelectOption>
                        {Array.from({ length: 31 }, (_, i) => (
                            <IonSelectOption key={i+1} value={(i+1).toString()}>{i+1}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Maand</IonLabel>
                    <IonSelect value={month} placeholder="Selecteer maand" onIonChange={e => setMonth(e.detail.value!)}>
                        <IonSelectOption value="*">*</IonSelectOption>
                        {Array.from({ length: 12 }, (_, i) => (
                            <IonSelectOption key={i+1} value={(i+1).toString()}>{i+1}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonLabel>Dag van de week</IonLabel>
                    <IonSelect value={dayOfWeek} placeholder="Selecteer dag" onIonChange={e => setDayOfWeek(e.detail.value!)}>
                        <IonSelectOption value="*">*</IonSelectOption>
                        {Array.from({ length: 7 }, (_, i) => (
                            <IonSelectOption key={i} value={i.toString()}>{i}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>

                <IonButton expand="full" onClick={handleCronChange}>
                    Build Cron Expression
                </IonButton>
            </IonCardContent>
        </IonCard>
    );
};

export default CronTimeBuilderComponent;
